<template>
  <div id="PanelTT">
    <p class="invis">{{tmp}}</p>

    <div class="head-buttons">
      <b-button size="sm" @click="$emit('insert', 'times')" variant="success"><b-icon-check2 /> Neu</b-button>
      <b-button size="sm" @click="kill(activeId)" variant="danger" :disabled="activeId==-1"><b-icon-check2 /> Löschen</b-button>
      <b-button size="sm" @click="$emit('save-kurs')" :disabled="!dirty"><b-icon-check2 /> Speichern</b-button>
    </div>

    <table class="data">
      <tr>
        <th></th>
        <th>Bez.</th>
        <th>Zeiten (*00 = unterrichtsfrei, | = Trennlinie)</th>
      </tr>
      <tr v-for="(t, key) in times" :key=key :class="{active: activeId==t.id}">
        <td @click="rowClick(t.id)"><b-icon-circle-fill @click="kill(t.id)" /></td>
        <td @click="rowClick(t.id)">
          <input v-if="activeId==t.id" type="text" placeholder="Bez." v-model.trim="t.bez" @input="$emit('set-dirty','times')">
          <span v-else>{{t.bez}}</span>
        </td>
        <td @click="rowClick(t.id)">
          <input v-if="activeId==t.id" type="text" class="tt" placeholder="Zeiten" v-model.trim="t.tt" @input="$emit('set-dirty','times')">
          <span v-else>{{t.tt}}</span>
        </td>
      </tr>

    </table>
  </div>
</template>

<script>
import { bus } from '../main'
export default {
  name: "PanelTT",
  data() {
    return {
      activeId: -1,  
    }
  },
  props: ['times', 'timesId', 'dirty', 'insert', 'del', 'tmp'],
  methods: {
     rowClick(id) {
      this.activeId= id
    },
    kill(id) {
      if (this.activeId == id) this.$emit('del', 'times', id)
    },
  },
  computed: {
  },
  created() {
    bus.$on('setActiveRow', (AId) => {
      this.activeId = AId
    })
    if (this.timesId > 0) this.activeId= this.timesId
  },
}
</script>

<style scoped lang="sass">
  th, td
    padding-right: 10px
  input
    width: 100px
  input.tt
    width: 700px

</style>