<template>
  <div
    class="dropdown is-right"
    :class="{'is-active': isDropdownActive}"
    v-on-clickaway="away"
  >
    <div
      class="dropdown-trigger"
      @click="labelClick()"
    >
      <a
        aria-haspopup="true"
        aria-controls="{'dropdown-menu'}"
      >
        <span class="label" :class="{empty: label == '', bold: isDropdownActive}">{{label}}</span>
        <span class="icon">
          <i
            class="fa fa-angle-down"
            aria-hidden="true"
          ></i>
        
        </span>
  
      </a>
    </div>
    <div v-if="isDropdownActive"
      class="dropdown-menu"
      role="menu"
    >
      <div class="dropdown-content">
        <div class="marker"></div>
        <a v-for="(o,k) in options" :key=k href="#" @click.prevent="itemClick(o)" :class="{disabled: o.disabled}" class="dropdown-item" v-html=o.caption></a>
      </div>
    </div>
  </div>
</template>
<script>
import { mixin as clickaway } from 'vue-clickaway';
export default {
  props: ['id', 'label', 'options', 'show', 'markerColor', 'dropdownitem-click'],
  mixins: [ clickaway ],
  data() {
    return {
      isDropdownActive: false,
      react: false,
    }
  },
  methods: {
    away() {
      this.isDropdownActive = false;
    },
    labelClick() {
      if (!this.isDropdownActive) {
        this.$emit('show')
        this.react= true
        
      }
      this.isDropdownActive = !this.isDropdownActive
    },

    itemClick(o) {
      //const x = window.pageXOffset, y = window.pageYOffset
      //console.log(x,y,o)
      this.isDropdownActive = false
      this.$emit('dropdownitem-click', o) 
      //if (x || y ) setTimeout(function() {window.scrollTo(x,y)}, 0) 
    },
  },
  updated() {
    if (this.react) {
      
      const c = this.$el,
        r= c.getBoundingClientRect(),
        parent = c.parentElement,
        rParent = parent.getBoundingClientRect(),
        menu = c.children[1], 
        marker = menu.children[0].children[0],
        rMenu = menu.getBoundingClientRect()
      let bLeft = true,
        bTop = true
      //console.log(rParent, r, rMenu, window.innerWidth, window.innerHeight)
      
      
      if (rParent.left + rParent.width / 2 + rMenu.width < window.innerWidth) {
        menu.style.left = "50%"
      } else  {
        menu.style.left = (r.width / 2 - rMenu.width -10) + "px"
        bLeft= false
      }

      if (rParent.top + rParent.height + rMenu.height > window.innerHeight) {
        menu.style.top = (rMenu.height * -1 + rParent.height / 2) +"px"
        bTop = false
      } else menu.style.top = "50%"
      
      //console.log(marker.classList, bLeft, bTop)
      if (bLeft) {
        marker.style.right= "auto"
        marker.style.left= "2px"
      } else {
        marker.style.left= "auto"
        marker.style.right= "2px"
      }
      if (bTop) {
        marker.style.bottom= "auto"
        marker.style.top= "2px"
      } else {
        marker.style.top= "auto"
        marker.style.bottom= "2px"
      }
      if (bLeft) {
        if (bTop) {
          marker.style.borderWidth = "14px 14px 0 0"
          marker.style.borderColor= this.FMarkerColor +" transparent transparent transparent"
        } else {
          marker.style.borderWidth = "14px 0 0 14px"
          marker.style.borderColor= "transparent transparent transparent " + this.FMarkerColor;
        }
      } else {
        if (bTop) {
          marker.style.borderWidth = "0 14px 14px 0 "
          marker.style.borderColor= "transparent " + this.FMarkerColor + " transparent transparent"
        } else {
          marker.style.borderWidth = "0 0 14px 14px"
          marker.style.borderColor= "transparent transparent " + this.FMarkerColor + " transparent"
        }  
      }
      //console.log(this.FMarkerColor, marker.style.borderColor)
      this.react = false  
    }  
  },
  computed: {  
    FMarkerColor: {
      get: function() {
        return this.markerColor != "" ? this.markerColor : "#35b19b" 
      },
      set: function(AColor) {
        this.FMarkerColor = AColor
      }
    },
  },
};
</script>

<style scoped lang="sass">
.bold
  font-weight: bold



</style>