<template>
  <div id="PanelBackups">
    <h3>Backups</h3>
    <div class="head-buttons" style= "margin-bottom:10px">
      <b-button size="sm" @click="del()" :disabled="selected.length == 0" variant="danger"><b-icon icon="x" /> Löschen</b-button>
      <b-button size="sm" @click="restore()" :disabled="selected.length != 1"><b-icon icon="cloud-arrow-down" /> Wiederherstellen</b-button>
    </div>
    <b-form-select id="fileList" v-model="selected" :options="files" multiple :select-size="20"></b-form-select>
    <!--
    <div class="mt-3">Selected: <strong>{{ selected }}</strong></div>
    -->
    nach <b-input type=text v-model.number="holdBackups" /> Tagen löschen
  </div>
</template>

<script>

var axios = require("axios").default

export default {
  name: "PanelBackups",
  data() {
    return {
      selected: [],
      files: [
        { value: null, text: 'Please select some item' },
      ]   
    }
  },
  props: ['FholdBackups', 'set-holdbackups'],
  methods: {
    del() {
      if (!confirm("Auswahl löschen?")) return
      const s= "backups=1&del=1&files=" + encodeURIComponent(JSON.stringify(this.selected)), t= this
      axios({
          method: 'post',
          url: 'api.php',
          data: s,
          responseType: 'json',
        }).then (response => {
          //console.log(response)
          if (response.data.err==1) {
            alert(JSON.stringify(response.data))
          } else {
            //alert(JSON.stringify(response.data))
            t.files= t.files.filter(z => z.value.indexOf(t.selected) == -1)
            
            if (t.files.length) t.selected= [t.files[0].value]
            else t.selected = []
          }
        }) 
    },
    restore() {
      if (!confirm("Aktuelle Daten überschreiben?")) return
      const s= "backups=1&restore=1&file=" + encodeURIComponent(this.selected[0])
      axios({
          method: 'post',
          url: 'api.php',
          data: s,
          responseType: 'json',
        }).then (response => {
          //console.log(response)
          if (response.data.err==1) {
            alert(JSON.stringify(response.data))
          } else {
            //alert(JSON.stringify(response.data))
            location.replace("/index.html")
          }
        }) 
    },
    listFiles() {
      const s= "backups=1&dir=1", t=this
      axios({
          method: 'post',
          url: 'api.php',
          data: s,
          responseType: 'json',
        }).then (response => {
          //console.log(response)
          if (response.data.err==1) {
            alert(JSON.stringify(response.data))
          } else {
            const patt= /(\d{4})-*(\d{2})-*(\d{2})_*(\d\d)(\d\d).*\.php/i
            let m
            t.files= []
            t.selected = []
            for (let f of response.data.files) {
              m = patt.exec(f)
              t.files.push({value: f, text: m!==null ? m[1] + "-" + m[2] + "-" + m[3] + "   " + m[4] + ":" + m[5] : f})
            }
          }
        }) 
    }
  },
  computed: {
    holdBackups: {
      get: function() {
        return this.FholdBackups 
      },
      set: function(AValue) {
        this.$emit('set-holdbackups', AValue)
      }
    }
    
  },
  created() {
    this.listFiles()
  }
}
</script>

<style scoped lang="sass">
.custom-select
  margin: 0 auto
  display: block
  width: 200px
  font-size: 10pt
  padding: 0 !important

#fileList 
  margin-bottom: 0.6em
  option
    padding-left: 5px !important
  
input[type=text]
  display: inline
  width: 45px 

</style>