<template>
  <div id="PanelUR">
    <h3>Räume</h3>
    <div class="head-buttons">
      <b-button size="sm" @click="$emit('insert', 'ur')" variant="success">Neuer Raum</b-button>
      <b-button size="sm" @click="kill(activeId)" variant="danger" :disabled="activeId==-1">Raum löschen</b-button>
      <b-button size="sm" @click="$emit('save-kurs')" :disabled="!dirty">Speichern</b-button>
    </div>
    <table class="data">
      <tr>
        <th></th>
        <th>Sort.</th>
        <th>Name</th>
        <th>2.Zeile</th>
        <th class="h-center v-center">aktiv &nbsp;</th>
        <th>Dozent</th>
      </tr>
      <tr v-for="(r,key) in ur" :key=key :class="{active: activeId==r.id}">
        <td @click="rowClick(r.id)"><b-icon-circle-fill @click="kill(r.id)" /></td>
        <td @click="rowClick(r.id)" class="sort">
          <input v-if="activeId==r.id" type="text" v-model.trim= "r.sort" @input="$emit('set-dirty', 'ur')">
          <span v-else>{{r.sort}}</span>
        </td>
        <td @click="rowClick(r.id)">
          <input v-if="activeId==r.id" type="text" v-model.trim= "r.bez" @input="$emit('set-dirty', 'ur')">
          <span v-else>{{r.bez}}</span>
        </td>
        <td @click="rowClick(r.id)">
          <input v-if="activeId==r.id" type="text" v-model.trim= "r.zeile2" @input="$emit('set-dirty', 'ur')">
          <span v-else>{{r.zeile2}}</span>
        </td>
        <td @click="rowClick(r.id)" class="h-center"><b-form-checkbox v-model="r.active" @change="$emit('set-dirty', 'ur')" /></td>
        <td @click="rowClick(r.id)">
          <dropdown v-if="activeId==r.id" :options="ddOptions" :label="getDozName(r.doz)" @show="beforeDDOpen(r.id)" @dropdownitem-click="dropdownitemClick" :markerColor = "markerColor" />
          <span v-else>{{getDozName(r.doz)}}</span>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { bus } from '../main'
import dropdown from "./Dropdown.vue"
export default {
  name: "PanelUR",
  data() {
    return {
      activeId: -1,
      ddOptions: [{disabled: false, name: ""}, ],
      markerColor: "navy",
    }
  },
  props: ['doz', 'ixDoz', 'ur', 'ixUr', 'dirty', 'insert', 'del', 'set-dirty', 'save-kurs', 'change-ur-doz'],
  components: {dropdown},
  
  methods: {
    beforeDDOpen(urId) {
      let l=[], f=[]
      for (let ur of this.ur) {
        if (ur.doz > 0) l.push(ur.doz)
      }
      for (let doz of this.doz) {
        if (l.indexOf(doz.id) == -1) {
          f.push({id: doz.id, caption: this.getDozName(doz.id), urId: urId, disabled: false})
        }
      }
      f.push({caption:"<i class='fa fa-lg fa-times text-danger'></i> löschen", id: 0, urId: urId, disabled: false})
      this.ddOptions = f
    },

    dropdownitemClick(el) {
      //console.log(el)
      this.$emit('change-ur-doz', el.urId, el.id)
    },
    rowClick(id) {
      this.activeId = id
    },
    kill(id) {
      if (this.activeId == id) this.$emit('del', 'ur', id)
    },

    shortName: (p) => {
      return p.name + (p.vname ? ' ' + p.vname[0] + '.' : '')
    },
    getDozName(id) {
     return (id === undefined || id == 0 || id==null) ? ' ... ' : this.shortName(this.doz[this.ixDoz[id]]) 
    },

    filterDoz() {
      let l=[]
      for (let ur of this.ur) {
        if (ur.doz > 0) l.push(ur.doz)
      }
      
      let f= this.doz.filter(z => l.indexOf(z.id) == -1)
      this.cDoz= f.length
      return f
    },
      
  },
  computed: {
    
  },
  created() {
    bus.$on('setActiveRow', (AId) => {
      this.activeId = AId
    })

    if (this.ur.length) this.activeId= this.ur[0].id
  },
  mounted() {

  }
}
</script>

<style scoped lang="sass">
  table.data
    td, th
      padding: 2px 8px 2px 4px

    td.sort 
      text-transform: uppercase
      input
        width: 30px

  .extraButton
    padding-left: 10px

</style>