<template>
  <div id="PanelEns">
    <div v-if="!showDet">
      <h3>Ensembles</h3>
      <div class="head-buttons">
        <b-button size="sm" @click="$emit('insert', 'ens')" variant="success">Neues Ensemble</b-button>
        <b-button size="sm" @click="kill(activeId)" variant="danger" :disabled="activeId==-1">Ensemble löschen</b-button>
        <b-button size="sm" @click="$emit('save-kurs')" :disabled="!dirty">Speichern</b-button>
      </div>
      
      <table class="data">
        <tr v-for="(e,key) in ens" :key=key :class="{active: activeId==e.id}">
          <td @click="rowClick(e.id)"><b-icon-circle-fill @click="kill(e.id)" /></td>
          
          <td @click="rowClick(e.id)">
            <input v-model.trim="e.name" @input="$emit('set-dirty', 'ens')">
            <p>{{e.studs.length}} <b>Teilnehmer:</b> {{studNames(e.studs)}}</p>
            <p>{{e.doz.length}} <b>Dozenten:</b> {{dozNames(e.doz)}}</p>
            
          </td>
          <td @click="rowClick(e.id)">
            <input class="soll" v-model.number="e.soll" @keydown="$emit('set-dirty', 'ens')">
          </td>
          <td @click="rowClick(e.id)" class="last">
            <b-button size="sm" @click="detailsOn(e)">Ändern</b-button>
          </td>
        </tr>
      </table>
    </div>
    <div v-else class="det">
      <table>
        <tr>
          <th><input class="filter" type="text" placeholder="Suchen..." v-model.trim="filterName" /></th>
          <th>{{curEns}}</th>
          <th class="h-right last">
            <b-button title="Abbrechen" size="sm" @click="detailsOff(false)" variant="danger"><b-icon icon="x-circle-fill" /></b-button>
            &nbsp;
            <b-button title="Übernehmen" size="sm" @click="detailsOff(true)"><b-icon icon="check-circle-fill" /></b-button>
          </th>
        </tr>
        <tr>
          <td>Teilnehmer</td>
          <td class="b-left">Dozenten</td>
          <td></td>
        </tr>
        <tr>
          <td>
            <b-form-checkbox-group
              v-model="selectedStuds"
              :options="optStuds"
              class="mb-3"
              value-field="item"
              text-field="name"
              stacked
              @change="dirtyDet=true"
            />
          </td>
          <td class="b-left">
            <b-form-checkbox-group
              v-model="selectedDoz"
              :options="optDoz"
              class="mb-3"
              value-field="item"
              text-field="name"
              stacked
              @change="dirtyDet=true"
            />
          </td>
           <td></td>
        </tr>

      </table>
    </div>
  </div>
</template>

<script>
import { bus } from '../main'
export default {
  name: "PanelEns",
  data() {
    return {
      selectedStuds: [],
      selectedDoz: [],
      optStuds: [],
      optDoz: [],
      activeId: -1,
      curEns: '',
      dirtyDet: false, 
      showDet: false,
      FFilterName: '', 

    }
  },
  props: ['studs', 'ens', 'doz', 'ixStuds', 'ixDoz', 'dirty', 'tmp', 'change-ens', 'set-dirty', 'insert', 'del'],
  methods: {
    rowClick(id) {
      this.activeId = id
    },
    kill(id) {
      if (this.activeId == id) this.$emit('del', 'ens', id)
    },
    shortName: (p) => {
      return p.name + (p.vname ? ' ' + p.vname[0] + '.' : '')
    },

    dozNames(arr) {
      let r= [], nr
      for (let i of arr) {
        nr= this.ixDoz[i]
        r.push(this.shortName(this.doz[nr]))
      }
      return(r.join(', '))
    },
    studNames(arr) {
      let r= [], nr
      for (let i of arr) {
        nr= this.ixStuds[i]
        r.push(this.studs[nr].name+' '+ this.studs[nr].vname)
      }
      return(r.join(', '))
    },
    
    detailsOn(e) {
      this.dirtyDet= false
      this.selectedStuds= e.studs
      this.selectedDoz= e.doz
      this.showDet = true
      this.curEns = e.name
      this.filterName = ''
    },
    detailsOff(bSave) {
      this.filterName = ''
      if (this.dirtyDet && bSave) this.$emit('change-ens', this.activeId, this.selectedStuds, this.selectedDoz)
      this.showDet = false

    },
  },
  computed: {
    filterName: {
      get: function () {
        return this.FFilterName
      },
      set: function(AValue) {
        this.FFilterName = AValue
        const s= AValue.toUpperCase()
        this.optStuds = []
        for (let stud of this.studs)  {
          if (stud.name.toUpperCase().startsWith(s)) this.optStuds.push({item: stud.id, name: stud.name+' '+stud.vname})
        }
      }
    }
  },

  created() {
    bus.$on('setActiveRow', (AId) => {
      this.activeId = AId
    })

    let i
    for (i of this.studs)  {
      this.optStuds.push({item: i.id, name: i.name+' '+i.vname})
    }
    for (i of this.doz)  {
      this.optDoz.push({item: i.id, name: this.shortName(i)})
    }

    if (this.ens.length) this.activeId= this.ens[0].id
  },

  mounted: function() {

  },
  destroyed() {
    this.showDet = false
  }
}
</script>

<style scoped lang="sass">
  .data
    td
      vertical-align: top

      p
        margin: 0 0 0 1.5em

    td.last
      padding-right: 5px
  
  .det
    table
      margin: 0 auto

    th
      border-bottom: 1px solid gray
      text-align: left
      padding-left: 15px
    th.last
      padding-left: 80px
      
    td
      border: none
      padding: 3px 15px
      text-align: left
      vertical-align: top

    td.b-left
      border-left: 1px dotted gray

input.filter
  width: 75px
  border: 1px solid silver

    
</style>