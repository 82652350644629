module.exports = {
  getPercent: (min, sec) => {
    alert(100 * sec / min)
  },
  oneDay: 86400000,
  fmtDate: (ADate) => {
    let s= ADate.toISOString()
    return s.substr(0, 10)
  },
  fmtDateTime: (ADate) => {
    let s = ADate.toISOString()
    return s.substr(0, 10) + ' ' + s.substr(11, 5)
  },
  dateAddDays: (ADate, days) => {
    let i = ADate.getMilliseconds()
    ADate.setMilliseconds(i + 86400000*days)
    return ADate
  },
}
