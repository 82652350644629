<template>
    <div id="PanelUTable">
    <h3>Übeplan</h3>
    <div class="invis">{{tmp}}</div>
    <b-button size="sm" @click="$emit('save-kurs')" :disabled="!dirty">Speichern</b-button>
    <table>
      <tr>
        <th v-for="(z, k) in zis" :key=k>
          {{z.bez}}
        </th>
      </tr>
      <tr v-for="(t, key) in tms" :key=key :class="{break: t.break}">
        <td v-for="(z, k) in zis" :key=k :class="{res: z.lessons[key]}" @click="$emit('toggle-res', z.id, key)">
          {{t.bez.replace('*', '')}}
        </td>
      </tr>
    </table>
    </div>
</template>

<script>
export default {
  name: "PanelUTable",
  data() {
    return {
      zis: [],
    }
  },
  props: ['ur', 'tms', 'dirty', 'tmp', 'save-kurs', 'toggle-res'],
  methods: {
    
  },
  computed: {

  },
  mounted() {

  },
  created() {
    this.zis= this.ur.filter(z => z.active && !z.doz)
  }
}
</script>

<style scoped lang="sass">
  table
    margin: 10px auto 10px auto
    
    td, th
      border: 1px solid gray
      padding: 1px 2px
      min-width: 70px
    th
      font-weight: normal
      background: #edededba
    td
      cursor: pointer
      
    td.res
      background: #f0bd4263
      color: black

    tr.break
      td
        border-bottom-width: 2px
</style>