import Vue from 'vue'
import App from './App.vue'
import "vue-trix"

/*
import VueRouter from 'vue-router'
const Foo = { template: '<div>foo</div>' }
const Bar = { template: '<div>bar</div>' }
const routes = [
  { path: '/foo', component: Foo },
  { path: '/bar', component: Bar }
]
const router = new VueRouter({
  routes // short for `routes: routes`
})

Vue.use(VueRouter)
*/
Vue.config.productionTip = false
export const bus = new Vue()

new Vue({
  //router,
  render: function (h) { return h(App) },
}).$mount('#app')
