<template>
  <div id="PanelTable">
    <div class="invis">{{tmp}}</div>
    <!--
    <loading :active.sync="isLoading" 
      :can-cancel="true" 
      :is-full-page="true">
    </loading>
    -->
    <div v-if="show==0">
    <h3>Stundenplan</h3>
    
      <div class="head-buttons">
        <div>
        <b-form-checkbox v-model="showAll">Übezeiten</b-form-checkbox>
        </div>
        <b-button size="sm" @click="$emit('clear-all-lessons')" variant="danger">Alles löschen</b-button>
        <b-button size="sm" @click="$emit('save-kurs')" :disabled="!dirty">Speichern</b-button>
        <b-button size="sm" @click="$emit('publish', 'print')" class="print" variant="dark">Drucken</b-button>
        <b-button size="sm" @click="show=2" class="print-last"><b-icon icon="tools" /></b-button>

        <b-button size="sm" @click="$emit('publish')" class="publish" variant="warning">Veröffentlichen</b-button>
        <b-button size="sm" @click="$emit('deactivate')" class="last" v-if="published" title="Stundenplan in User-App deaktivieren"  variant="danger"><b-icon icon="x" /></b-button>
        ({{published ? "zuletzt "+published: "nicht veröffentlicht"}})
        
      </div>
      <div id="dayCaption">
        <b-form-datepicker
          title= "Datum einfügen"
          v-model="FDay"
          locale="de"
          size="sm"
          label-no-date-selected= ""
          @context="onDateContext"
        />
      </div>
      
      <table class="lessontable">
        <tr>
          <th class="no-border tt">
            <select v-model="FtimesId">
              <option v-for="(o,k) in optTT" :key=k :value="o.value">
                {{ o.text }}
              </option>
            </select>
          </th>
          <th v-for="(d, key) in sortedDoz" :key=key class="doz" :class="{ok: dozComplete(d)}">
            {{ur[d.nrUR].bez}}
            <br>{{shortName(d)}}
          </th>
          <th class="no-border tt"></th>
        </tr>
        
        <tr :class="{hidden: !showAll && t.uez, break:t.break}" v-for="(t, key) in tms" :key=key>
          <td class="time">{{t.bez}}</td>
          <td :class="{res: d.lessons[key]==-1, lesson: d.lessons[key] > 0}" v-for="(d, k) in sortedDoz" :key=k :title="t.bez">
            <div v-if="t.uez" class="cell-uez" @click="$emit('set-lesson', d.id, key, d.lessons[key]==0 ? -1 : 0)">xx</div>
            <div v-else>
              <dropdown class="no-border" :options="ddOptions" :label="getName(d.lessons[key])" @show="beforeDDOpen(d,key,t.uez)" @dropdownitem-click="dropdownitemClick" :markerColor= "markerColor"></dropdown>
            </div>
          </td>
          <td class="time">{{t.bez}}</td>
        </tr>
          
        </table>
        <div class="trix" @click="show=1">
          <h4>Tagesmeldungen</h4>
          <b-button size="sm" @click="show=1">Bearbeiten</b-button>
          <br>
          <br>
          <div class="trix-content flat de" v-html="dayText.de">
          </div>
          <br>
          <div class="trix-content flat en" v-html="dayText.en ? dayText.en : dayText.de"></div>
          <br>
          
        </div>
    </div>
    <div v-if="show==1" class="trix">
      <h3>Tagesmeldungen</h3>
      <div class="trix-container de">
        <VueTrix v-model.trim="dayText.de" placeholder="" @input="$emit('set-day-text', dayText)" />
      </div>
      <br>
      <div class="trix-container en">
        <VueTrix v-model.trim="dayText.en" placeholder="Enter content" @input="$emit('set-day-text', dayText)" />
      </div>
      <br>
      <b-button size="sm" @click="show=0"><b-icon icon="check" /> Übernehmen</b-button>
    </div>
    <div v-if="show==2">
      <h3>Druckoptionen</h3>
      <table class="po">
      <tr>
        <td class="h-right">Dozenten pro Seite</td>
        <td class="h-left"><input type="text" v-model.number="printOptions.cols" @input="$emit('set-printoptions', printOptions)" /></td>
        <td class="h-right">(5)</td>
      </tr>
      <tr>
        <td class="h-right">Schriftgröße Teilnehmer</td>
        <td class="h-left"><input type="text" v-model.number="printOptions.fsStuds" @input="$emit('set-printoptions', printOptions)" /></td>
        <td class="h-right">(12)</td>
      </tr>
      <tr>
        <td class="h-right">Schriftgröße Dozenten</td>
        <td class="h-left"><input type="text" v-model.number="printOptions.fsDoz" @input="$emit('set-printoptions', printOptions)" /></td>
        <td class="h-right">(12)</td>
      </tr>
      </table>
      <b-button size="sm" @click="show=0"><b-icon icon="check" /> Übernehmen</b-button>
    </div>
  </div>
</template>

<script>
//import Loading from 'vue-loading-overlay'
//import 'vue-loading-overlay/dist/vue-loading.css'
import Vue from "vue";
import VueTrix from "vue-trix";
Vue.use(VueTrix);

import dropdown from "./Dropdown.vue"

export default {
  name: "PanelTable",
  components: {VueTrix, dropdown},
  data() {
    return {
      show: 0,
      showAll: false, 
      optTT: [],
      sortedDoz: [],
      markerColor: 'navy',
      ddOptions: [{disabled: false, caption: ""},],
      //isLoading: false,
      //dateSelected: "",
      FDay: '',
    }
  },
  props: ['studs', 'doz', 'ens', 'ur', 'ixStuds', 'ixDoz', 'ixEns', 'timesId', 'times', 'tmp', 'tms', 'printOptions', 'dirty', 
    'published', 'day', 'dayText',
    'set-dirty', 'set-lesson', 'set-times', 'set-caption', 'set-day-text', 'set-printoptions', 'clear-all-lessons', 'save-kurs', 'deactivate'],
  
  methods: {
    
    beforeDDOpen(doz, lessonNr, uez) {
      let stud, ens, ink, id, complete, list= [], l= {}
      if (!uez) {
        for (let job of doz.jobs.list) {
          complete = false
          l = {id: job.id, name: job.name, soll:job.soll, ok: true, rest: 0, dozId: doz.id, lessonNr: lessonNr}

          if (l.id >= 1000) {
            ens= this.ens[this.ixEns[l.id-1000]]
            l.rest = ens.soll - ens.ist
            if (l.rest <= 0) complete = true

          } else if (l.id > 0) {
            stud= this.studs[this.ixStuds[l.id]]
            for (let d of stud.doz) {
              if (d.id == doz.id) {
                l.rest= d.soll - d.ist
                complete = l.rest <= 0
                break
              }
            }
          }
          
          if (!complete) {
            for (let d of this.sortedDoz) {
              if (d.id != doz.id) {
            
                id = d.lessons[lessonNr]
                if (id >= 1000) ink= this.ens[this.ixEns[id-1000]].ink
                else if (id > 0) ink= this.studs[this.ixStuds[id]].ink 
                else ink = []

                if (id > 0 && ink.indexOf(l.id) > -1) {
                  l.ok= false
                  l.lastDoz= this.doz[this.ixDoz[d.id]].name
                  break
                } 
              }
            }
            
            l.disabled= !l.ok
            l.caption= l.name
            if (l.rest > 1) l.caption += " (" + l.rest + ")"
            list.push(l)
          }
        }
      }
      let i= this.isNull(doz.lessons[lessonNr]) ? 0 : doz.lessons[lessonNr]
      if (i !=0 ) list.push({dozId: doz.id, caption:"<i class='fa fa-lg fa-times text-danger'></i> löschen", id: 0, lessonNr: lessonNr})
      if (i > -1) list.push({dozId: doz.id, caption:"<i class='fa fa-lg fa-times'></i> reservieren", id: -1, lessonNr: lessonNr})

      this.ddOptions = list
    },

    dropdownitemClick(el) {
      this.$emit('set-lesson', el.dozId, el.lessonNr, el.id)
    },

    dozComplete(doz) {
      let rest= 0, stud, ens
      for (let job of doz.jobs.list) {
        if (job.id >= 1000) {
          ens= this.ens[this.ixEns[job.id-1000]]
          rest += ens.soll - ens.ist
        } else if (job.id > 0) {
          stud= this.studs[this.ixStuds[job.id]]
          for (let d of stud.doz) {
            if (d.id == doz.id) {
              rest += d.soll - d.ist
              break
            }
          }
        }
      }
      return rest == 0
    },

    shortName: (p) => {
      return p.name + (p.vname ? ' ' + p.vname[0] + '.' : '')
    },
    getName(i) {
      if (this.isNull(i)) return ''
      else if (i==-1) return ''
      else if (i < 1000) {
        const s= this.studs[this.ixStuds[i]]
        if (s) return s.name + (s.vname ? ' ' + s.vname[0] + '.' : '')
      } 
      else if (i >= 0) return this.ens[this.ixEns[i-1000]].name   
      
    },
    isNull(a) {
      return a==0 || a===undefined || a==null || a==0
    },
    
    
    onDateContext(ctx) {
      if (ctx.selectedYMD) this.$emit("set-caption", ctx.selectedFormatted, this.FDay)
    }
  },
  computed: { 
    FtimesId: {
      get: function() {
        return this.timesId
      },
      set: function(AId) {
        //this.$emit('set-dirty')
        this.$emit('set-times', AId)
      }
    },
    /*
    FDayText: {
      get: function() {
        return this.dayText
      },
      set: function(AText) {
        this.$emit('set-day-text', AText)
      } 
    },
    FDayTextE: {
      get: function() {
        return this.dayTextE
      },
      set: function(AText) {
        this.$emit('set-day-text-e', AText)
      } 
    },
    */
  },
  
  created() {
    //this.isLoading = true

    this.FDay = this.day
    this.optTT= []  
    
    for (let t of this.times) {
      this.optTT.push({value:t.id, text:t.bez})
    }
    
    this.sortedDoz= []
    for (let doz of this.doz) {
      if (doz.nrUR == -1) continue
      this.sortedDoz.push(doz)
    }
    this.sortedDoz.sort((a,b) => a.nrUR - b.nrUR)
  },
  
  mounted() {},
  updated() {
    /*
    const c = this.$el,
    r= c.getBoundingClientRect()
    document.querySelector('body').style.width = r.width+"px"
    console.log(r)
    */
  }

}
</script>

<style scoped lang="sass">
  h4
    font-size: 12pt
  
  .head-buttons
    div
      display: inline-block
      line-height: 22px
      margin-right: 4px

    button
      margin-left: 4px

    button.publish, button.print
      border-top-right-radius: 0px
      border-bottom-right-radius: 0px
    
    button.last, button.print-last
      margin-left: 0px
      position: relative
      border-top-left-radius: 0px
      border-bottom-left-radius: 0px
      left: -1px
    
    button.print-last
      background: gray

  .dd
    .dropdown-trigger
      border: 1px solid black
      span.icon
        display: none

  table
    margin: 0 auto

    .hidden 
      display: none

    tr.break
      td 
        border-bottom-width: 2px
    
    th.doz
      padding: 1px 3px
      line-height: 110%
      white-space: nowrap
      font-weight: normal
      border: 1px solid gray
      background: left top no-repeat #eee url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAABhWlDQ1BJQ0MgcHJvZmlsZQAAKJF9kT1Iw0AcxV9TpSIVBTuICAapThZERcRJqlgEC6Wt0KqDyaUfQpOGJMXFUXAtOPixWHVwcdbVwVUQBD9AnBydFF2kxP8lhRYxHhz34929x907QKiVmGq2jQGqZhnJWFTMZFfEwCuCGEQPhjAjMVOPpxbS8Bxf9/Dx9S7Cs7zP/Tm6lJzJAJ9IPMt0wyJeJ57atHTO+8QhVpQU4nPiUYMuSPzIddnlN84FhwWeGTLSyTniELFYaGG5hVnRUIknicOKqlG+kHFZ4bzFWS1VWOOe/IXBnLac4jrNAcSwiDgSECGjgg2UYCFCq0aKiSTtRz38/Y4/QS6ZXBtg5JhHGSokxw/+B7+7NfMT425SMAq0v9j2xzAQ2AXqVdv+Prbt+gngfwautKa/XAOmP0mvNrXwEdC9DVxcNzV5D7jcAfqedMmQHMlPU8jngfcz+qYs0HsLdK66vTX2cfoApKmrpRvg4BAYKVD2mse7O1p7+/dMo78fA5hy4fYf7cIAAAAGYktHRAAAAAAAAPlDu38AAAAJcEhZcwAALiMAAC4jAXilP3YAAAAHdElNRQfkDB8QLBsj+VjOAAAAGXRFWHRDb21tZW50AENyZWF0ZWQgd2l0aCBHSU1QV4EOFwAAAFdJREFUKM+dzbkRgDAMBdGVWyB1SP8VEZJSg5wxHD6kv/mbtavujtB2HhQVursVFQK58xOm8BeGcQ+G8Agu8QxO8QoOcQR2cRT+cAa+cBbeWIEABrgCARpU8Ea2oEcx/QAAAABJRU5ErkJggg==')
    th.doz.ok
      background-image:  url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAABhWlDQ1BJQ0MgcHJvZmlsZQAAKJF9kT1Iw0AcxV9TpSIVBTuICAapThZERcRJqlgEC6Wt0KqDyaUfQpOGJMXFUXAtOPixWHVwcdbVwVUQBD9AnBydFF2kxP8lhRYxHhz34929x907QKiVmGq2jQGqZhnJWFTMZFfEwCuCGEQPhjAjMVOPpxbS8Bxf9/Dx9S7Cs7zP/Tm6lJzJAJ9IPMt0wyJeJ57atHTO+8QhVpQU4nPiUYMuSPzIddnlN84FhwWeGTLSyTniELFYaGG5hVnRUIknicOKqlG+kHFZ4bzFWS1VWOOe/IXBnLac4jrNAcSwiDgSECGjgg2UYCFCq0aKiSTtRz38/Y4/QS6ZXBtg5JhHGSokxw/+B7+7NfMT425SMAq0v9j2xzAQ2AXqVdv+Prbt+gngfwautKa/XAOmP0mvNrXwEdC9DVxcNzV5D7jcAfqedMmQHMlPU8jngfcz+qYs0HsLdK66vTX2cfoApKmrpRvg4BAYKVD2mse7O1p7+/dMo78fA5hy4fYf7cIAAAAGYktHRAAZAIgAIq71RVAAAAAJcEhZcwAALiMAAC4jAXilP3YAAAAHdElNRQfkDB8QMyfBzCrXAAAAGXRFWHRDb21tZW50AENyZWF0ZWQgd2l0aCBHSU1QV4EOFwAAAFdJREFUKM+dzbkRgDAMBdGVuyB1lZRAlaSUIWcMhw/pb/5mbTuqI3TtJ0WF7m5FhUDu/IQp/IVh3IMhPIJLPINTvIJDHIFdHIU/nIEvnIU3ViCAAa5AgAbqRUwYdYf5owAAAABJRU5ErkJggg==')

     
    th.tt
      background: white
    th, td.time
      background: #eee

    .no-border
      border: none
    td 
      text-align: center
      padding: 0px 6px

    td.time
      padding: 2px 4px
      text-align: right

    .l-counter
      color: silver
      margin-right: 3px
      width: 6px
      height: 6px
      position: relative
      top: -7px
    
    td.res
      padding: 0
      background:  #f0bd4263
    
    div.cell-uez
      color: transparent
      width: 100%
      height: 100%
      cursor: pointer

    td.lesson
      background: #ededed59
    
    input
      width: 50px
      border: 1px solid gray

  select
    width: 45px
  .extraButton
    padding-left: 10px

  .trix
    width: 400px
    margin: 1em auto



  .trix-content.flat
    border: 1px solid gray
    padding: 0.1em
    margin-top: 0
    min-height: 5em

  .trix-content.de, .trix-container.de
      background: no-repeat white 380px 2px url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAIAAAD5gJpuAAACJklEQVQozwEbAuT9Af8AAAAAAAAAAAAAAP4AAAAAAAAAAAAAAP4AAP4AAP4AAP4AAP4AAP4AAP4AAP4AAAH/AAAAe3sAAAD//v4A/v4A/f3//P0A/fz//PwA/Pz/+/v//Pz/+/v//Pz/+/v0srIEAAAAAAD//+TlAP39AP39//v7AP39//v7//v7//v7//r7//v6//r6//v7AR0d/gAABAA1Nf8pKgAVFwD//v/8/AD9/f/9/f/8/P/8/P/9/f/8/P/8/P/8/Pr6+gYXF/63twHp6ekVFRUAAAD///8A/wD/AP/////////////////////6+vr////6+voLCwvt7e0EAAAAAAAA////////AAAA/////////////////////////////////////wAA////BAAAAP////////8A/wD/AP///////////////////////////////////wD///7+/gH7Ly8BcXH/6+sA/f3//Pz//Pz//Pz//P3//fz//Pz//f3//Pz//PwA/f0BFxfshoYE/tHRA8bG/97eAPv7//v7//r6//r6//v7//r6//v7//v7//r6//z7APz9ACQkAAAABP4AAAL7/AAZGf/7+wD8/QD8+wD7+wH7+wD8/AD7+wD8/AD8/AD9/QD9/QD9/f4AAAH1AAD+AAD+AAD+AAD+AAAAAAD+AAD+AAD+AAD+AAD+AAD+AAD+AAD+AAAAAAAAAAB81lpNfAbIMwAAAABJRU5ErkJggg==')
  .trix-content.en, .trix-container.en
    background: no-repeat white 380px 2px url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAIAAAD5gJpuAAACJklEQVQozwEbAuT9AZKZviY8LsPC2unsAAAA/f3+/XhcLdSGlP/9/S19b3COyf/7AvX2+B0lCULQtt9MVgQSDwxH3L5GZ1Xd4/XHz+f9/wElIRImMQsA+vn/IRJJNh37/P08KxM1wpkArIqStdcCvtz3vRhEAaeoJODJXU8k5+f5AQEAANHdANLcAQEA4u3/ZwXcLsOoAXWDoK3ZiYLIAgAAAOXn73/sLuL9Iv2DgHlpKgAAAAD38wD39wAAAIvflwtGPNlRYkJKqNTY8vj5+QDZ3e3+/v//////////////////////VVX/Tk7////////////////////+/v/M1ukBrGF9UzILANHcAPv7APr6APv7APr6AP7+//n5/Pr6//r6/vv7/vr6AP39BCgbjMz0ANnd7f7+//////////////////////89Pfw2OP///////v////39/fz9/Pv8/MnV6AJmgcWOntBbZ7nV1vAA7u36ZGAAAAD/9ff/+fkAAAAAAP/mQ0LCnLgcHp5ZZLM3PoMC+Pj8FxMGpZlHH6yP8Z2eYxFnAAAA/vn5//r6AP//O0CnFL2+NQHo0EXCLjUfAP78AlxdJVtPKPiIedI/XYH2NxIM/f//AAEpGwEpGP///x0nElxfrMMpPRWcnGnLf2thPQTn6+2WRmavJT/OpcH7/Pf8/f1hViW2x/D//PxH1+ypsb0A/v2rtcIUHhF5i4raeZcn9UIeToo/UAAAAABJRU5ErkJggg==')
  .trix-container
    background-position: 383px 13px !important

  #dayCaption
    width: 270px
    margin: 0.4em auto

    input
      width: 350px
      border: 1px solid gray
      border-radius: 3px
      margin-right: 1px

  

  table.po
    margin: 1em auto 2em auto
    td 
      padding: 4px
      border: none
</style>