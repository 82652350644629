<template>
  <div id="app">
    <header @click="home()" :style="{backgroundImage: 'url(logo.jpg)'}">
      <p v-if="show==2">Austrian Masterplan <span class="version">[{{version}}]</span></p>
      <h2 v-else-if="show==1">{{ret.kurs.kursCaption}}</h2>
    </header>
      <b-nav id="menu" v-if="show<2" pills>
        <b-nav-item :active="lang=='de'" @click="lang='de'">DE</b-nav-item>
        <b-nav-item :active="lang=='en'" @click="lang='en'">EN</b-nav-item>
        <b-nav-item-dropdown
          v-if = "show==1"
          id = "mDropdown"
          text = "MENU"
          toggle-class = "nav-link-custom"
          right
          >
          <b-dropdown-item v-if="ret.studId>0 && showMain>-1" @click="showStud()">Persönlicher Stundenplan</b-dropdown-item>
          <b-dropdown-divider v-if="ret.studId>0 && showMain>-1" />
          <b-dropdown-item :active="d.id==ret.dozId" @click="showDoz(d.id)" v-for="(d, key) in sortedDoz" :key=key>{{d.vname}} {{d.name}}</b-dropdown-item>
          <b-dropdown-divider v-if="showMain>-1" />
          
          <b-dropdown-item @click="logOut(true)"><span v-if="lang=='de'">Abmelden</span><span v-else>Logout</span></b-dropdown-item>
        </b-nav-item-dropdown>
      </b-nav>
    <p v-if="show<2" class="invis">{{tmp}}</p>
    <div id="login" v-if="show==0">
      
      <table>
        <tr>
          <td></td>
          <td>
            <h2><span v-if="lang=='de'">Anmelden</span><span v-else>Login</span></h2>
          </td>
          <td></td>
        </tr>
        <!--
        <tr>
          <td class="h-right label">
            <p v-if="lang=='de'">Kursbeginn</p>
            <p v-else>Startdate<br>of classes</p>
          </td>
          <td>
            <b-form-datepicker v-model="user.startKurs" class="mb-2" 
              :locale = "lang"
              :start-weekday = 1
              :hide-header= true
              :date-format-options= "{ 'year': 'numeric', 'month': 'long', 'day': 'numeric', 'weekday': 'short' }"
              v-bind = "dpLabels[lang] || {}"

            />
          </td>
          <td class="blind">
            <p v-if="lang=='de'">Kursbeginn</p>
            <p v-else>Startdate<br>of classes</p>
          </td>
        </tr>
        -->
        <tr>
          <td class="h-right">
            PIN
          </td>
          <td>
            <b-form-input v-model="user.pin" @keyup.13="login(true)"></b-form-input>
          </td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>
            <b-form-checkbox v-model="user.stay" @keyup.13="login(true)"> 
              <span v-if="lang=='de'">angemeldet bleiben</span>
              <span v-else>stay logged in</span>
            </b-form-checkbox>
          </td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>
            <br>
            <b-button class="amc login" @click="login(true)">Login</b-button>
          </td>
          <td></td>
        </tr>
      </table>
      
    </div>
    <div id="main" v-else-if="show==1">
      
      
      <div v-if="showMain==1">
        <h2>{{ret.kurs.kursCaption}}</h2>
        <h3>
        {{ret.kurs.doz[ret.dozId].vname}} {{ret.kurs.doz[ret.dozId].name}}
        <br>
        {{ret.kurs.doz[ret.dozId].ur}}
        </h3>
        {{ret.kurs.dayCaption}}
        <!--
        <br>
        {{ret.kurs.doz[ret.dozId].lessons}}
        <br>
        {{dozTable(ret.dozId)}}
        -->
        <table class="doz-table">
          <tr v-for="(l, key) in dozTable(ret.dozId)" :key=key :class="{break: l.break}">
            <td class="h-right time">{{l.time}}</td>
            <td class="name">
              <b>{{l.name}}</b>
              <p v-if="l.members" v-html="l.members">
               
              </p>
            </td>
            
          </tr>
        </table>
        
      </div>
      <div v-else-if="showMain==0">
        <h3>
        <span v-if="lang=='de'">Persönlicher Stundenplan</span><span v-else>Your schedule of today</span>
        <br>
        {{ret.stud}}
        </h3>
        {{ret.kurs.dayCaption}}
        <!--
        <br> <input type="text" v-model.number="ret.studId">
        {{ret.kurs.names[ret.studId].lessons}}
        
        <br>
        {{studTable(ret.studId)}}
        -->
  
        <table class="stud-table">
          <tr v-for="(l, key) in studTable(ret.studId)" :key=key :class="{break: l.break}">
            <td class="h-right time v-top">{{l.time}}</td>
            <td class="v-top name">
              <p v-if="l.name">{{l.name}}</p>
              {{l.doz}}<br>{{l.ur}}
            </td>
          </tr>
        </table>
        
        <div v-if="ret.kurs.dayText.de && lang=='de'" class="dayText" v-html="ret.kurs.dayText.de"></div>
        <div v-if="ret.kurs.dayText.de && lang=='en'" class="dayText" v-html="ret.kurs.dayText.en ? ret.kurs.dayText.en : ret.kurs.dayText.de"></div>
        
      </div>
      <p v-else>Stundenplan nicht verfügbar.</p>
      <p>Informationen über die Austrian Master Classes erhalten Sie auf 
        <a href="https://www.austrian-master-classes.com/de" target="_blank">www.austrian-master-classes.com</a>,
        bei <a href="https://www.facebook.com/AustrianMasterClasses/" target="_blank">facebook</a> 
        und <a href="https://www.instagram.com/austrian.master.classes/">Instagram</a>.
        
      </p>  
    </div>
    <div v-else-if="show==-1" class="text-center">
      <b-spinner style="margin-top:5em" label="loading ..."></b-spinner>
    </div>
    

    <PanelKurs 
      v-if= "show==2"
      :dpLabels = "dpLabels"
      :lang = "lang"
    />
    
    <footer>
      &copy; <a href="https://www.raumplan.org" target="_blank">raumplan.org</a>, Peter Langgartner, Salzburg<br>
      Components &amp; Icons by <a href="https://bootstrap-vue.org/">bootstrap-vue.org</a>
    </footer>
  </div>
  
</template>

<script>
import Vue from "vue"

//import VueRouter from "vue-router";
//Vue.use(VueRouter);
/*
var router = new VueRouter({
    mode: 'history',
    routes: []
  });
*/

import { BootstrapVue, IconsPlugin } from "bootstrap-vue"

// Install BootstrapVue
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-vue/dist/bootstrap-vue.css"

var axios = require("axios").default;
axios.defaults.baseURL = process.env.NODE_ENV === "development" ? "http://localhost:8081/data" : "/data"
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded"

import PanelKurs from './components/PanelKurs.vue'
import { bus } from './main'


export default {
  name: "App",
  //router,
  components: {
    PanelKurs
  },
  data() {
    return {
      caption: "",
      ret: {},
      tmp: false,
      user: {startKurs: '', pin: '', stay: true},
      lang: 'de',
      sortedDoz: [],
      dpLabels: {
        de: {
          labelPrevDecade: 'Vorheriges Jahrzehnt',
          labelPrevYear: 'Vorheriges Jahr',
          labelPrevMonth: 'Vorheriger Monat',
          labelCurrentMonth: 'Aktueller Monat',
          labelNextMonth: 'Nächster Monat',
          labelNextYear: 'Nächstes Jahr',
          labelNextDecade: 'Nächstes Jahrzehnt',
          labelToday: 'Heute',
          labelSelected: 'Ausgewähltes Datum',
          labelNoDateSelected: 'Kein Datum gewählt',
          labelCalendar: 'Kalender',
          labelNav: 'Kalendernavigation',
          labelHelp: 'Mit den Pfeiltasten durch den Kalender navigieren'
        }
      },
      show: -1, // 0: login, 1:user, 2:kurse
      showMain: 0, // 0: stud, 1:doz, -1 Fehler
      version: '',
    };
  },
  methods: {
    login(fromButton) {
      if (this.user.pin=="") {
        alert ('PIN fehlt')
        return
      }
      //console.log (this.user)
      const s = "login=1&data="+encodeURIComponent(JSON.stringify(this.user))
      
      axios({
        method: 'post',
        url: 'api.php',
        data: s,
        responseType: 'json',
        timeout: 400
        
      }).then (response => {
        //console.log(response)
        if (response.data.err==1) {
          alert('Fehler beim Login')
        } else {
          //console.log(response.data)
          if (response.data.show==2) {
            this.show =2
          } else {
            this.ret= response.data
            this.show = this.ret.show

            if (this.ret.kurs === undefined) this.showMain = -1
            else {
              this.sortedDoz=[]
              let d
              for (let i in this.ret.kurs.doz) {
                d = this.ret.kurs.doz[i]
                this.sortedDoz.push({id: d.id, name: d.name, vname: d.vname})
              }
              this.sortedDoz.sort(function(a,b) {
                if (a.name > b.name) return 1
                else if (a.name < b.name) return -1
                else return a.vname > b.vname? 1 : -1
              })
              if (this.ret.dozId) this.showMain= 1
              else this.showMain= 0
            }
            
            if (this.ret.show==0 || this.ret.msg) alert(this.ret.msg)
            //else if (this.ret.show==1 && ) alert(this.ret.msg)
            
            
          }
          if (this.show >0 && fromButton && this.user.stay) localStorage.setItem('login', JSON.stringify(this.user))
        }
      })
      .catch(error => console.log(error))
    },

    logOut(bConfirm) {
      const s= this.lang=="de" ? "Abmelden?" : "Please confirm to log out"
      if (bConfirm && !confirm(s)) return
      this.user.pin = ""
      this.user.startKurs = ""
      localStorage.removeItem("login")
      this.show=0
    },

    showDoz(id) {
      this.ret.dozId = id
      this.showMain= 1
      this.tmp = !this.tmp
    },
    showStud() {
      this.ret.dozId=0
      this.showMain= 0 
    },

    dozTable(dozId) {
      let lessons= [], id, x, s, bBreak
      for (x of this.ret.kurs.times) {
        s = x
        if (s.indexOf("*") == -1) {
          bBreak= s.indexOf("|") > -1
          if (bBreak) s = s.replace("|", "")
          lessons.push({time: s, break: bBreak})
        } else lessons.push({time: ""})  
      }
      for (x in this.ret.kurs.doz[dozId].lessons) {
        id = this.ret.kurs.doz[dozId].lessons[x]
        if (id > 0) {
          lessons[x].name = this.ret.kurs.names[id].name
          lessons[x].members = id >= 1000  ? this.ret.kurs.names[id].members : ""
        }
      }
      lessons = lessons.filter(z => z.time != '')
      lessons[lessons.length-1].break = false
      return lessons

    },
    
    studTable(studId) {
      let lessons= [], id, dozId, doz, x, s, bBreak
      for (x of this.ret.kurs.times) {
        s = x
        if (s.indexOf("*") == -1) {
          bBreak= s.indexOf("|") > -1
          if (bBreak) s= s.replace("|", "")
          lessons.push({time: s, break: bBreak})
        } else lessons.push({time: ""})  
      }
      
      for (x in this.ret.kurs.names[studId].lessons) {
        id = this.ret.kurs.names[studId].lessons[x].stud
        dozId = this.ret.kurs.names[studId].lessons[x].doz
        doz= this.ret.kurs.doz[dozId]
        if (id > 0) {
          lessons[x].name = id==studId ? "" : this.ret.kurs.names[id].name 
          lessons[x].doz= doz.vname +' '+ doz.name 
          lessons[x].ur= doz.ur 
        }
      }
      lessons = lessons.filter(z => z.time != '')
      lessons[lessons.length-1].break = false
      return lessons
    },

    home() {
      if (this.show == 2) window.open('https://www.austrian-master-classes.com', '_blank')
      else if (this.show == 1) this.login(false)
    },
    onTest(msg) {
      alert(msg)
    },
    changeTestdata(val) {
      this.testData= val
    },
    

  },

  created() {
    const s = localStorage.getItem('login')
    if (s) {
      this.user = JSON.parse(s)
      this.login()
    } else this.show=0
    
    bus.$on('setVersion', (data) => {
      this.version = data
    })

    bus.$on('bye', () => {
      this.logOut(false)
    })

  },
  mounted: function() {
    /*
    const parameters = this.$route.query
    console.log(parameters)
    const name = this.$route.query.name
    console.log(name)
    */
  },
};
</script>

<style lang="sass">
#app
  font-family: "Open Sans", Verdana, sans-serif
  font-size: 10pt
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-align: center
  color: #2c3e50
  margin-top: 0

h2
  font-size: 14pt
  margin-top: 0.5em

a, a:hover
  text-decoration: none
.h-left
  text-align: left !important
.h-right
  text-align: right !important
.h-center
  text-align: center !important

.v-top 
  vertical-align: top 
.v-middle 
  vertical-align: middle
.v-bottom
  vertical-align: bottom 

.fw-normal
  font-weight: normal !important
  
header
  background: #e6e8eb no-repeat 10px 6px
  background-size: 130px
  height: 35px
  padding: 3px 0 0 0
  border-bottom: 2px solid #b81f24
  font-size: 12pt
  text-align: center
  cursor: pointer
  
  h2 
    margin-top: 2px
    padding: 0

  p
    text-align: left
    cursor: pointer
    font-size: 10pt
    margin: 4px 0 0 145px
  
  .version
    font-size: 8pt
    margin-right: 1em
    float: right

table
  border-collapse: collapse
  td
    border: 1px solid gray


.invis
  display: none

label, .form-control, .b-form-btn-label-control
  font-size: 10pt
  
#menu
  position: absolute
  right: 5px
  top: 5px
  
  a
    color: black
    text-decoration: none
  
  .nav-pills, .nav-link.active
    background-color: #cc3543
    color: white

  #mDropdown
    .nav-pills.show, a.nav-link.active, .nav-link
      background-color: transparent !important
  
  .dropdown-item.active
    background-color: #ddd    

#login

  table 
    margin: 1em auto
    .label
      line-height: 130%
      padding-top: 5px
    td
      padding: 0px 5px
      border: none

    td.blind
      color: white

#main
  margin: 1em
  h2
    display: none
  h3
    font-size: 12pt

  div, p
    margin: 0
    padding: 0
  a
    color: darkred
    border-bottom: 1px dotted gray

  .doz-table, .stud-table 
    margin: 1em auto 2em auto

    td 
      border: 1px solid gray
      padding: 3px 10px
    td.time 
      padding: 3px 5px

    td.name
      min-width: 150px

    tr.break 
      td
        border-bottom-width: 4px
  
  .stud-table
    td
      padding-bottom: 10px
      line-height: 120%   

      p
        margin-bottom: 5px
  
  .dayText
    margin: 1em auto 2em auto

    div, h1
      margin: 0
      padding: 0
      line-height: 1.3

    h1
      color: #dc3545
      font-size: 1.2em
      font-weight: normal

button.amc
  background-color: #cc3543
  border-color: darkred
  padding: 3px 40px

.lessontable
  td.dropdown
    padding: 0 2px

  
  td.empty 
    div.dropdown
      width: 100%
      margin: 0 !important

    .btn[aria-expanded="false"]
      background: transparent !important
      border-color: transparent
      color: black
      width: 100%
    
  .btn
    font-size: inherit
    padding: 0px 5px
    min-width: 16px

  .b-dropdown.res .btn
    background: #f0bd4263 !important
    color: black


table.data
  .btn
    padding: 2px 5px
    font-size: inherit
  th
    font-weight: 400
    text-align: left
    border-bottom: 1px solid gray

  span
    cursor: pointer

.head-buttons
  button
    margin: 0 1px

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before
  border-color: #6c757d
  background-color: #6c757d

.b-form-datepicker
  .btn
    padding: 2px 4px 4px 4px
    font-size: 0.9em

table.data
  margin: 1em auto

  td
    text-align: left
    border: none
    padding: 4px 2px
    border-bottom: 1px solid silver

  td:first-child
    padding-left: 5px
    color: white

  tr.active
    td
      background: #eee
    td:first-child
      color: black

  td.fname input
    width: 140px
  td.vname input
    width: 120px
  td.pin input
    width: 80px

  td.id
    text-align: right
    color: silver

  input.soll 
    width: 25px

// ------------------------
.dropdown
  cursor: pointer
  display: inline-block

  .dropdown-trigger
    white-space: nowrap
    border: 1px solid gray
    border-radius: 4px
    padding: 2px 0px 2px 6px
    background: white
    
    span.empty
      padding: 0 3em
    span.icon
      padding: 2px 8px

  div.marker
    position: absolute
    left: 0px
    top: 0px
    border-style: solid
    width: 0px
    height: 0px
    -webkit-transform: rotate(360deg)

  .dropdown-menu
    box-shadow: 2px 2px 4px gray
    left: 50%
    top:50%

  .dropdown-item
    font-size: 10pt
    padding: 0.2rem 1rem

  .dropdown-item.disabled, .dropdown-item:disabled
    color: #ff000075

  .dropdown-item.disabled
    color: red

.dropdown.no-border
  width: 100%

  .dropdown-trigger 
    border: none
    padding: 0
    background: transparent
  span.icon
    display: none 

.dropdown.is-active .dropdown-menu
  display: block
// ------------------------

.trix
  .trix-button-group
    margin-bottom: 3px
    border-bottom: none
  .trix-button-group--block-tools
    margin-left: 5px !important

  .trix-button--icon-strike, .trix-button--icon-link,.trix-button--icon-quote, 
  .trix-button--icon-bullet-list, .trix-button--icon-number-list, 
  .trix-button--icon-decrease-nesting-level, .trix-button--icon-increase-nesting-level,
  .trix-button-group--file-tools, .trix-button-group--history-tools
    display: none

  div, h1
    line-height: 1.3

  h1
    color: #dc3545

footer
  background: transparent
  margin: 2em auto 1.5em auto
  padding: 0.5em 0
  color: gray
  font-size: 9pt

  a
    text-decoration: none
    color: gray
    border-bottom: 1px dotted #aaa

  a:hover
    text-decoration: none
    color: gray


@media (max-width: 600px)
  #app
    header
      h2
        display: none
        
    
    header p
      display: none
    
    .nav-link
      padding: 3px 10px

  #main
    h2
      font-size:12pt
      display: block
      margin-top: 0
  #menu
    right: 0px

</style>
