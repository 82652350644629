<template>
  <div id="PanelKurs">
    <!--
    {{kurs.maxIds}}
   
    <loading :active.sync="isLoading" 
        :can-cancel="true" 
        :is-full-page="true">
    </loading> 
    -->
    <div class="nav">
      <ul>
        <li :class="{active: show==0}" @click="show=0"><b-icon-circle-fill class="active" v-if="show==0" /> Kurse (aktuell {{kurs.bez}})</li>
        <li :class="{active: show==1}" @click="show=1"><b-icon-circle-fill v-if="show==1" /> Stundenplan</li>
        <li :class="{active: show==2}" @click="show=2"><b-icon-circle-fill v-if="show==2" /> Übeplan</li>
        <li :class="{active: show==3}" @click="show=3"><b-icon-circle-fill v-if="show==3" /> Teilnehmer</li>
        <li :class="{active: show==4}" @click="show=4"><b-icon-circle-fill v-if="show==4" /> Ensembles</li>
        <li :class="{active: show==5}" @click="show=5"><b-icon-circle-fill v-if="show==5" /> Dozenten</li>
        <li :class="{active: show==6}" @click="show=6"><b-icon-circle-fill v-if="show==6" /> Räume</li>
        <li :class="{active: show==7}" @click="show=7"><b-icon-circle-fill v-if="show==7" /> Zeiteinteilung</li>
        <li :class="{active: show==8}" @click="show=8"><b-icon-circle-fill v-if="show==8" /> Backups</li>
        <!--
        <li :class="{active: show==8}" @click="show=8"><b-icon-circle-fill v-if="show==0" /> App</li>
        -->
        <li @click="logOut()">Abmelden</li>
      </ul>
    </div>

    <div v-if="show==0">
      
      <div class="head-buttons" style= "margin-bottom:10px">
        <b-button size="sm" @click="insertKurs">Neuer Kurs</b-button>
        <b-button size="sm" @click="saveKurse" :disabled="!dirty.kurse">Speichern</b-button>
      </div>
      <div class="kurs" v-for="(k, key) in kurse" :key=key :class="{activeKurs: k.id==kurs.id, 'active': activeRowId==k.id}" @click="activeRowId=k.id">
        <div class="header">
          <table>
            <tr>
              <td class="h-left icon"><b-icon-circle-fill :class="{active: k.id==kurs.id}" /></td>
              <td class="h-left bez">{{k.bez}}</td>
              <td class="h-left datum">{{vonBis(k)}}</td>
            </tr>
          </table>
        </div>
        
        <table class="detail" v-if="activeRowId==k.id">
          <tr>
            <td class="h-right">Name (kurz)</td>
            <td>
              <b-input class="bez" type="text" placeholder="" v-model.trim="k.bez" @keydown="dirty.kurse=true" />
            </td>
            <td class="h-right">
              
              <b-button title="Öffnen" size="sm" @click="initKurs(kurse[ixKurse[k.id]])" v-if="k.id!=kurs.id"><b-icon-folder2-open font-scale="1.2" /> Kurs öffnen</b-button>&nbsp;
              <b-button title="Löschen" size="sm" @click="delKurs(k.id)" variant="danger" v-if="k.id!=kurs.id"><b-icon-trash font-scale="1.2" /> Löschen</b-button>
        
            </td>
          </tr>
          <tr>
            <td class="h-right">Name</td>
            <td colspan=2>
              <b-input class="caption" type="text" placeholder="" v-model.trim="k.caption" @keydown="dirty.kurse=true" />
            </td>
          </tr>
          <tr>
            <td class="h-right">von</td>
            <td colspan=2>
              <b-form-datepicker class="date" v-model="k.von"
                :locale = "lang"
                :start-weekday = 1
                :hide-header= true
                :date-format-options= "{ 'year': 'numeric', 'month': 'numeric', 'day': 'numeric', 'weekday': 'short' }"
                v-bind = "dpLabels[lang] || {}"
                @input = "dirty.kurse=true"
              
              />
            </td>
          </tr>
          <tr>
            <td class="h-right">bis</td>
            <td colspan=2>
              <b-form-datepicker class="date" v-model="k.bis"
                :locale = "lang"
                :start-weekday = 1
                :hide-header= true
                :date-format-options= "{ 'year': 'numeric', 'month': 'numeric', 'day': 'numeric', 'weekday': 'short' }"
                v-bind = "dpLabels[lang] || {}"
                @input = "dirty.kurse=true"
              
              />
            </td>
          </tr>
          <tr v-if="k.id==kurs.id">
            <td class="h-right">upload</td>
            <td colspan=2> 
              <b-form-file accept=".xlsx" class="file float-left" placeholder="Datei ..." v-model="file" />&nbsp;
              <b-button class="float-left btnUpload" @click="upload()" :disabled="!file.name">Hochladen</b-button>
            </td>
          </tr>
        </table>
            
      </div>
      
    </div>

    <PanelTable v-if="show==1"
      :studs= "kurs.studs"
      :doz= "kurs.doz"
      :ens= "kurs.ens"
      :ur= "kurs.ur"
      :ixStuds= "ixStuds"
      :ixDoz= "ixDoz"
      :ixEns= "ixEns"
      :times= "kurs.times"
      :timesId= "kurs.timesId"
      :tms= "times"
      :printOptions= "kurs.printOptions"
      :day = "kurs.day"
      :dayText = "kurs.dayText"
      :dirty= "dirty.kurse"
      :tmp= "tmp"
      :published= "ini.published"
      @set-dirty= "setDirty"
      @set-lesson= "setLesson"
      @set-printoptions= "setPrintOptions"
      @save-kurs= "saveKurse"
      @publish = "publish"
      @clear-all-lessons= "clearAllLessons"
      @set-times= "setTimes"  
      @set-caption= "setCaption"
      @set-day-text= "setDayText"
      @deactivate= "deactivate"
      
    />

    <PanelUTable v-if="show==2"
      :ur= "kurs.ur"
      :tms= "times"
      :tmp= "tmp"
      :dirty= "dirty.kurse"
      @toggle-res= "toggleRes"
      @save-kurs= "saveKurse"
    />

    <PanelStud v-if="show==3"
      :studs= "kurs.studs"
      :doz= "kurs.doz"
      :ixDoz= "ixDoz"
      :ens= "kurs.ens"
      :dirty= "dirty.kurse"
      :tmp= "tmp"
      @insert= "insert"
      @del= "del"
      @change-stud-doz= "changeStudDoz"
      @add-doz= "addDoz"
      @set-dirty= "setDirty"
      @save-kurs= "saveKurse"
      
    />

    <PanelEns v-if="show==4" 
      :studs= "kurs.studs"
      :doz= "kurs.doz"
      :ixStuds= "ixStuds"
      :ixDoz= "ixDoz"
      :ens= "kurs.ens"
      :dirty= "dirty.kurse"
      @insert= "insert"
      @del= "del"
      @change-ens= "changeEns"
      @set-dirty= "setDirty"
      @save-kurs= "saveKurse"
      
    />

    <PanelDoz v-if="show==5"
      :doz= "kurs.doz"
      :studs= "kurs.studs"
      :ens= "kurs.ens"
      :ixStuds= "ixStuds"
      :dirty= "dirty.kurse"
      :tmp= "tmp"
      @set-dirty= "setDirty"
      @save-kurs= "saveKurse"
      @insert= "insert"
      @del= "del"
      
    />

    <PanelUR v-if="show==6"
      :ur= "kurs.ur"
      :doz= "kurs.doz"
      :ixDoz= "ixDoz"
      :dirty= "dirty.kurse" 
      @insert= "insert"
      @del= "del"
      @save-kurs= "saveKurse"
      @set-dirty= "setDirty"
      @change-ur-doz= "changeUrDoz"
      
    />

    <PanelTT v-if="show==7"
      :times= "kurs.times"
      :ixTimes= "ixTimes"
      :dirty= "dirty.kurse"
      :tmp= "tmp"
      :timesId= "kurs.timesId"
      @insert= "insert"
      @del = "del"
      @save-kurs= "saveKurse"
      @set-dirty= "setDirty"
     
    />
    
    <PanelBackups v-if="show==8"
      :FholdBackups = "ini.holdBackups"
      
      @set-holdbackups = setHoldBackups
    />
      
  </div>
</template>

<script>
import PanelTable from './PanelTable.vue'
import PanelUTable from './PanelUTable.vue'
import PanelStud from './PanelStud.vue'
import PanelEns from './PanelEns.vue'
import PanelDoz from './PanelDoz.vue'
import PanelUR from './PanelUR.vue'
import PanelTT from './PanelTT.vue'
import PanelBackups from './PanelBackups.vue'

import global from './../globals.js'

//import Loading from 'vue-loading-overlay'
//import 'vue-loading-overlay/dist/vue-loading.css'

import { bus } from '../main'

var axios = require("axios").default
//axios.defaults.baseURL = process.env.NODE_ENV === "development" ? "http://localhost:8081" : "/"
//axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded"

export default {
  name: "PanelKurs",
  components: {
    PanelTable, PanelUTable, PanelStud, PanelEns, PanelDoz, PanelUR, PanelTT, PanelBackups
  },
  data() {
    return {
      FShow: 0, //0:kurse, 1:stundenplan, 2:übeplan, 3:studenten, 4:doz, 5:ens, 6:zi, 7:TT
      tmp: false,
      dirty: {kurse: false, studs: false, ens: false, doz: false, times: false, ur:false, stuPlan: true},
      //isLoading: false,
      ini: {},
      config: {},
      kurse: [],
      
      kurs: {
        id: 0, studs:[],  ens: [], doz: [], ur: [],
        maxIds: {studs:0, ens:0, doz:0, times:0, ur:0,},
        times:[{id:1, bez:"", tt: []},]
      },
      
      ixKurse: [],
      ixStuds: [],
      ixEns: [],
      ixDoz: [],
      ixTimes: [],
      ixUr : [],
      
      times: [],
  
      today: '',
      activeRowId: -1,
      file: [],
    }
  },
  props: ['dpLabels', 'lang'],
  methods: {
    
    logOut() {
      if (confirm('Abmelden?')) {
        if (this.dirty.kurse) {
          //alert('dirty')
          this.saveKurse()
        }
        bus.$emit('bye')
      }
    },

    upload() {
      //console.log(this.file)
      let formData = new FormData()
      formData.append('upload', 1)
      formData.append('file', this.file)
      //const s = "upload=1&f=" + encodeURIComponent(JSON.stringify(this.file))
      //alert(process.env.NODE_ENV)
      axios({
        method: 'post',
        url: 'api.php',
        data: formData,
        responseType: 'json',
        headers: {
         'Content-Type': 'multipart/form-data'
        },
        //timeout: 200   
      }).then (response => {
        //console.log(response)
        if (response.data.err==1) {
          alert(JSON.stringify(response.data))
        } else {
          let d
          this.kurs.doz = response.data.doz
          this.kurs.studs = response.data.studs
          this.kurs.ens = []
          for (d of this.kurs.doz) {
            this.kurs.maxIds.doz= Math.max(d.id, this.kurs.maxIds.doz)
          }
          for (d of this.kurs.studs) {
            this.kurs.maxIds.studs= Math.max(d.id, this.kurs.maxIds.studs)
          }
          this.kurs.maxIds.ens = 1000

          for (let ur of this.kurs.ur) {
            ur.doz = 0
            ur.lessons= []
          }
          this.reIndex()
          this.dirty= {kurse: true, studs:true, doz:true, ens:false, ur:false, times: false, stuPlan: true}
          this.sortKurs()
          
          //this.file = []
          if (response.data.msg) alert(response.data.msg)
        }
      }) 
    },

    vonBis(k) {
      return k.von.substr(8,2) +'.' + k.von.substr(5,2) + ". - " +
        k.bis.substr(8,2) + '.' + k.bis.substr(5,2) +". " + k.bis.substr(0,4)
    },

    reindexKurse() {
      this.ixKurse = []
      for (let i in this.kurse) this.ixKurse[this.kurse[i].id] = i
    },
    
    insertKurs() {
      let d = new Date(), d2 = global.dateAddDays(new Date(), 6), 
      k = JSON.parse(JSON.stringify(this.kurs))

      this.config.maxId ++
      k.id = this.config.maxId
      k.bez = "neu: " + k.bez
      k.von = global.fmtDate(d)
      k.bis = global.fmtDate(d2)
      this.kurse.splice(0, 0, k)
      this.reindexKurse()
      this.initKurs(this.kurse[0])
      this.dirty.kurse= true
    },

    saveKurse() {
    
      this.kurs.studs= this.kurs.studs.filter(z => z && z.name !="")
      this.kurs.ens= this.kurs.ens.filter(z => z && z.name !="")
      this.kurs.doz= this.kurs.doz.filter(z => z && z.name !="")
      this.kurs.ur= this.kurs.ur.filter(z => z.bez !="")
      this.kurs.times= this.kurs.times.filter(z => z.bez !="")

      this.sortKurs()
      this.kurse.sort((a, b) => a.von.localeCompare(b.von) * -1)

      let clone = JSON.parse(JSON.stringify(this.kurse))
      for (let kurs of clone) {
        if (kurs.id == this.kurs.id) {
          kurs.studs = kurs.studs.map((d) => {
            if (d.ensList) delete d.ensList
            if (d.ink) delete d.ink
            return d
          })
          kurs.ens = kurs.ens.map((d) => {
            if (d.ink) delete d.ink
            return d
          })
          kurs.doz = kurs.doz.map((d) => {
            if (d.jobs) delete d.jobs
            return d
          })
          break
        }
      }
      const s = "saveKurse=1&ini="+encodeURIComponent(JSON.stringify(this.ini))+"&kurse=" + encodeURIComponent(JSON.stringify(clone))+"&config=" + encodeURIComponent(JSON.stringify(this.config))

      axios({
        method: 'post',
        url: 'api.php',
        data: s,
        responseType: 'json',
      }).then (response => {
        if (response.data.err==1) {
          alert('Fehler beim Speichern')
        } else this.dirty.kurse=false
      })
    },

/// Stundenplan ---------------------------------------------
    setLesson(dozId, lessonNr, studId) {
      const dozNr = this.ixDoz[dozId],
        oldStudId = this.kurs.doz[dozNr].lessons[lessonNr]

      let oldStudNr = 0, studNr = 0, nr=0

      if (oldStudId >= 1000) {
        // Ensemble
        oldStudNr = this.ixEns[oldStudId - 1000]
        this.kurs.ens[oldStudNr].ist--
      } else if (oldStudId > 0) {
        
        oldStudNr = this.ixStuds[oldStudId]
        nr= this.findStudDozNr(dozId, this.kurs.studs[oldStudNr])
        this.kurs.studs[oldStudNr].doz[nr].ist--
      }

      if (studId >= 1000) {
        studNr = this.ixEns[studId - 1000]
        this.kurs.ens[studNr].ist++
      } else if (studId > 0) {
        studNr = this.ixStuds[studId]
        nr= this.findStudDozNr(dozId, this.kurs.studs[studNr])
        this.kurs.studs[studNr].doz[nr].ist++
      }
      for (let i=0; i < lessonNr; i++) {
        if (this.kurs.doz[dozNr].lessons[i] === undefined) this.kurs.doz[dozNr].lessons[i] =0  
      }
      this.kurs.doz[dozNr].lessons[lessonNr] = studId
      this.setDirty()
    },
   
    cutLessons() {
      const c= this.times.length
      for (let doz of this.kurs.doz) {
        //doz.studs= []
        if (c < doz.lessons.length) {
          doz.lessons= doz.lessons.slice(0, c)
        } else if (c > doz.lessons.length) {
          for (let i=doz.lessons.length; i < c; i++) doz.lessons.push(0)
        }
      }
    },

    findStudDozNr(id, AStud) {
      for (let i in AStud.doz) {
        if (id == AStud.doz[i].id) return i
      }
      return -1
    },

    initStuplan() { // studs, ens => doz.studs, write ink[], check lessons
      
      let i = 0, x, y, nr, stud, ens, doz, c
      if (this.dirty.times) this.setTimes(this.kurs.timesId)
      
      this.dirty.ens= true
      this.dirty.studs= true
      this.sortKurs()
      
      for (doz of this.kurs.doz) {
        doz.studs = []
        doz.jobs = {einzel: 0, ens: 0, list:[]}
        doz.nrUR = -1
      }
      for (stud of this.kurs.studs) {
        stud.ink=[]
        for (x of stud.doz) {
          x.ist = 0
          nr = this.ixDoz[x.id]
          //if (this.kurs.doz[nr].studs.indexOf(stud.id) == -1) 
          this.kurs.doz[nr].studs.push(stud.id)
          this.kurs.doz[nr].jobs.list.push({id: stud.id, name: stud.name + ' ' + stud.vname, soll: x.soll})
          this.kurs.doz[nr].jobs.einzel += x.soll
        }
        stud.ink.push(stud.id)
      }
      

      for (ens of this.kurs.ens) {
        ens.ink = []
        ens.ist = 0
        for (x of ens.doz) {
          nr = this.ixDoz[x]
          //if (this.kurs.doz[nr].studs.indexOf(ens.id) == -1) 
          this.kurs.doz[nr].studs.push(ens.id)
          this.kurs.doz[nr].jobs.list.push({id: ens.id, name: ens.name, soll: ens.soll})
          this.kurs.doz[nr].jobs.ens ++
        }
        for (x of ens.studs) {
          nr = this.ixStuds[x]
          this.kurs.studs[nr].ink.push(ens.id)
          //if (ens.ink.indexOf(x)== -1) ens.ink.push(x)
        }
      }
      for (ens of this.kurs.ens) {
        for (x of ens.studs) {
          nr = this.ixStuds[x]
          for (i of this.kurs.studs[nr].ink) {
            if (ens.ink.indexOf(i) == -1) ens.ink.push(i)
          }
        }
      }
      
      for (doz of this.kurs.doz) {
        
        if (!doz.lessons) continue
        c = this.times.length 
        for (i=0; i < c; i++) {
          x= doz.lessons[i]
          if (x > 0) {
            if (doz.studs.indexOf(x)== -1) {
              doz.lessons[i]=0 
              continue  
            }
            if (x>=1000) {
              nr= this.ixEns[x-1000]
              if (this.kurs.ens[nr].ist < this.kurs.ens[nr].soll) this.kurs.ens[nr].ist ++
              else doz.lessons[i]=0   
            } else {
              nr= this.ixStuds[x]
              y= this.findStudDozNr(doz.id, this.kurs.studs[nr])
              if (y >-1) {
                if (this.kurs.studs[nr].doz[y].ist < this.kurs.studs[nr].doz[y].soll) this.kurs.studs[nr].doz[y].ist ++
                else doz.lessons[i]=0 
              } 
              else doz.lessons[i]=0 
            }
          }
        }
      }
      
      c= this.kurs.ur.length
      for (i=0; i < c; i++) {
        x= this.kurs.ur[i].doz
        if (x) {
          nr= this.ixDoz[x]
          this.kurs.doz[nr].nrUR= i
        }
      }
      
      //console.log('initStuplan')
    },
    

//// aktueller Kurs --------------------------------------
    sortKurs() {
      
      if (this.dirty.ur) {
        this.kurs.ur.sort(function(a, b) {
          let r= a.sort.localeCompare(b.sort)
          if (r==0) r= a.bez.localeCompare(b.bez)
          return r
        })
      }
      if (this.dirty.doz || this.dirty.studs) {
        const t= this
        this.kurs.doz.sort(function(a, b) {
          let r= a.name.localeCompare(b.name)
          if (r==0) r= a.vname.localeCompare(b.vname)
          return r
        })
        /*
        for (let doz of this.kurs.doz) {
          if (doz.studs !== undefined) {
            doz.studs.sort(function (a,b) {
              let aa= t.kurs.studs[t.ixStuds[a.id]],
                bb= t.kurs.studs[t.ixStuds[b.id]],
                r= aa.name.localeCompare(bb.name)
              if (r==0) r= aa.vname.localeCompare(bb.vname)
              return r
            })
          }
        }
        */
        this.kurs.studs.sort(function(a, b) {
          let r= a.name.localeCompare(b.name)
          if (r==0) r= a.vname.localeCompare(b.vname)
          return r
        })
        for (let stud of this.kurs.studs) {
          stud.doz.sort(function (a,b) {
            let aa= t.kurs.doz[t.ixDoz[a.id]],
              bb= t.kurs.doz[t.ixDoz[b.id]],
              r= aa.name.localeCompare(bb.name)
            if (r==0) r= aa.vname.localeCompare(bb.vname)
            return r
          })
        }
      } 
      if (this.dirty.ens) {
        this.kurs.ens.sort((a, b) => a.name.localeCompare(b.name))
        const t= this
        for (let e of this.kurs.ens) {
          e.studs.sort(function (a,b) {
            let aa= t.kurs.studs[t.ixStuds[a]],
              bb= t.kurs.studs[t.ixStuds[b]],
              r= aa.name.localeCompare(bb.name)
            if (r==0) r= aa.vname.localeCompare(bb.vname)
            return r
          })
          e.doz.sort(function (a,b) {
            let aa= t.kurs.doz[t.ixDoz[a]],
              bb= t.kurs.doz[t.ixDoz[b]],
              r= aa.name.localeCompare(bb.name)
            if (r==0) r= aa.vname.localeCompare(bb.vname)
            return r
          })
        }
      } 
      if (this.dirty.times) {
        this.setTimes(this.kurs.timesId)
        this.kurs.times.sort((a, b) => a.bez.localeCompare(b.bez))
      }
      this.dirty= {kurse: this.dirty.kurse, studs: false, ens: false, doz:false, ur: false, times: false, stuPlan: this.dirty.stuPlan}
      this.reIndex()
    },

    publish(cmd) {
      if (!cmd) {
        let s=""
        if (this.kurs.bis=="") s="Kursende fehlt"
        else if (this.kurs.bis < this.today) s = "Kurs ist abgeschlossen"
        if (s) {
          alert(s)
          return
        }
        let d= new Date(), sDate= d.getDate()+"."
        s= d.getMonth() +1
        //if (s < 10) s= "0"+s
        sDate += s + "." + d.getFullYear()+" "
        s= d.getHours()
        if (s < 10) s= "0"+s
        sDate += s + ":"
        s= d.getMinutes()
        if (s < 10) s= "0"+s
        sDate += s

        this.ini.published = sDate
      }
  
      const addCmd = cmd  ? 'print' : "publish&date=" + encodeURIComponent(this.ini.published)
      const s = "publish=1&cmd="+addCmd+"&kurs="+encodeURIComponent(JSON.stringify(this.kurs))
    
      axios({
        method: 'post',
        url: 'api.php',
        data: s,
        responseType: 'json',
        //timeout: 200
        
      }).then (response => {
        if (response.data.err==1) {
          alert(JSON.stringify(response.data))
        } else {
          this.tmp = !this.tmp
          if (cmd == "print") window.open(location.hostname == "localhost" ? "http://localhost:8081/data/Ausdruck.php" : "data/Ausdruck.php")
        }
      })
    },

    reIndex() {
      let i, c
      this.ixStuds=[]
      c= this.kurs.studs.length
      for (i = 0; i < c; i++) this.ixStuds[this.kurs.studs[i].id] = i
    
      this.ixEns=[]
      c=this.kurs.ens.length
      for (i = 0; i < c; i++) this.ixEns[this.kurs.ens[i].id - 1000] = i
    
      this.ixDoz=[]
      c=this.kurs.doz.length
      for (i = 0; i < c; i++) this.ixDoz[this.kurs.doz[i].id] = i
    
      this.ixUr=[]
      c= this.kurs.ur.length
      for (let i = 0; i < c; i++) this.ixUr[this.kurs.ur[i].id] = i
    
      this.ixTimes=[]
      c= this.kurs.times.length
      for (i = 0; i < c; i++) this.ixTimes[this.kurs.times[i].id] = i
   
    },

    initKurs(k) {
      let err= []
      this.kurs= k
      this.config.lastId= k.id
      this.reIndex()
      
      for (let x of this.kurs.ur) {
        if (x.lessons === undefined) x.lessons = []
        if (this.ixDoz[x.doz] === undefined) {
          x.doz = 0
          this.dirty.ur= true
        }
      }
      
      for (let stud of this.kurs.studs) {
        err= []
        for (let doz of stud.doz) {
          if (this.ixDoz[doz.id] === undefined) {
            this.dirty.studs= true
            err.push(doz.id)
          }
        }
        if (err.length) {
          stud.doz = stud.doz.filter(z => err.indexOf(z.id) == -1) 
        }
      }

      this.setTimes(this.kurs.timesId)
      this.dirty= {kurse: this.dirty.kurse, studs: this.dirty.studs, ens: false, doz: false, times: false, ur: this.dirty.ur, stuPlan: true}

      if (!this.kurs.printOptions) this.kurs.printOptions={fsStuds: 12, fsDoz: 12, cols: 5}
      if (!this.kurs.printOptions.fsStuds) this.kurs.printOptions.fsStuds = 12
      if (!this.kurs.printOptions.fsDoz) this.kurs.printOptions.fsDoz = 5
      if (!this.kurs.printOptions.cols) this.kurs.printOptions.cols = 5
      
      if (typeof this.kurs.dayText != 'object') {
        this.kurs.dayText= {de: this.kurs.dayText, en: ''}  
      }
      //alert( typeof this.kurs.printOptions)
      //console.log(this.kurs.printOptions)
      //bus.$emit('changeTitle', this.kurs.bez)
    },
    delKurs(id) {
      if (!confirm("Kurs löschen?")) return
      this.kurse = this.kurse.filter(z => z.id != id)
      const s = "del="+id
      axios({
        method: 'post',
        url: 'api.php',
        data: s,
        responseType: 'json',
        //timeout: 200
        
      }).then (response => {
      
        if (response.data.err==1) {
          alert(response.data.msg)
        } else {
          this.dirty.kurse= true
        }
      }) // .then
    },

    insert(ATable) {
      if (ATable=="studs") {
        this.kurs.maxIds.studs++
        this.kurs.studs.splice(0,0, {id: this.kurs.maxIds.studs, name:"Neu", vname:"", doz:[], lessons:[]}) 
        bus.$emit('setActiveRow', this.kurs.maxIds.studs)
      } else if (ATable=="ens") {
        this.kurs.maxIds.ens++
        this.kurs.ens.splice(0,0, {id: this.kurs.maxIds.ens, name:"Ensemble ?", doz:[], studs:[], soll:0}) 
        bus.$emit('setActiveRow', this.kurs.maxIds.ens)
      } else if (ATable=="doz") {
        this.kurs.maxIds.doz++
        this.kurs.doz.splice(0,0, {id: this.kurs.maxIds.doz, name:"Neu", vname:"", lessons:[], studs:[], jobs:{einzel:0, ens:0, list:[]}, nrUR:-1}) 
        bus.$emit('setActiveRow', this.kurs.maxIds.doz)
      } else if (ATable=="ur") {
        this.kurs.maxIds.ur++
        this.kurs.ur.splice(0,0, {id: this.kurs.maxIds.ur, bez: "Neu", sort: "A", active: true, doz:0, lessons:[]})
        bus.$emit('setActiveRow', this.kurs.maxIds.ur) 
      } else if (ATable== "times") {
        this.kurs.maxIds.times ++
        this.kurs.times.splice(0, 0, {bez: "Neue Zeiteinteilung", tt:'9,10,11,12', id: this.kurs.maxIds.times})
        bus.$emit('setActiveRow', this.kurs.maxIds.times)
      }
      this.reIndex()
      this.setDirty(ATable)
    },

    canDelete(ATable, id) {
      if (id === undefined || id <= 0) return "ID ist undefiniert: " + id
      let o, o1

      if (ATable == "studs") {
        for (o of this.kurs.ens) {
          if (o.studs.indexOf(id) > -1) return "Teilnehmer ist einem Ensemble zugeteilt"
        }
      } else if (ATable == "ens") {
        o= this.kurs.ens[this.ixEns[id-1000]]
        if (o.doz.length) return 'Ensemble ist Dozent(en) zugeteilt'
      } else if (ATable == "doz") {
        for (o of this.kurs.ens) {
          if (o.doz.indexOf(id) > -1) return "Dozent ist einem Ensemble zugeteilt"
        }
        for (o of this.kurs.studs) {
          for (o1 of o.doz) {
            if (o1.id == id) return "Dozent hält Kurse"
          }
        }
      } else if (ATable == "ur") {
        o= this.kurs.ur[this.ixUr[id]]
        if (o.doz > 0) return "Unterrichtsraum ist einem Dozenten zugeteilt"
      } else if (ATable == "times" && id == this.kurs.timesId) return 'Zeiteinteilung in Verwendung'
      return ''
    },

    del(ATable, id) {
      const s= this.canDelete(ATable, id)
      if (s) {
        alert(s)
        return
      }
      if (!confirm('Löschen?')) return
      if (ATable=="studs") this.kurs.studs= this.kurs.studs.filter(z => z.id != id)
      else if (ATable=="ens") this.kurs.ens= this.kurs.ens.filter(z => z.id != id)
      else if (ATable=="doz") this.kurs.doz= this.kurs.doz.filter(z => z.id != id)
      else if (ATable=="ur") this.kurs.ur= this.kurs.ur.filter(z => z.id != id)
      else if (ATable=="times") this.kurs.times= this.kurs.times.filter(z => z.id != id)

      this.reIndex()
      this.setDirty(ATable)
      //bus.$emit('setActiveRow', nextId)
    },

    setCaption(s, day) {
      this.kurs.dayCaption= s
      this.kurs.day= day
      this.dirty.kurse= true
    },
    setDayText(s) {
      this.kurs.dayText= s
      this.dirty.kurse= true
    },
  
    changeUrDoz(id, idDoz) {
      this.kurs.ur[this.ixUr[id]].doz= idDoz
      if (idDoz) this.kurs.ur[this.ixUr[id]].active = true
      this.setDirty()
    },

    changeStudDoz(studNr, kursNr, id) {
      if (id==0) {
        this.kurs.studs[studNr].doz.splice(kursNr,1)
      } else {
        this.kurs.studs[studNr].doz[kursNr].id= id
      }
      this.setDirty()
    },
    addDoz(id) {
      let nr= this.ixStuds[id]
      this.kurs.studs[nr].doz.push({id: 0, soll:1, ist:0})
      this.setDirty()
    },

    changeEns(id, arrStuds, arrDoz) {
      let ens= this.kurs.ens[this.ixEns[id-1000]]
      if (!ens.soll) ens.soll = arrStuds.length
      ens.studs= arrStuds
      ens.doz= arrDoz
      this.setDirty('ens')
    },

    setDirty(table) {
      this.dirty.kurse= true
      if (table == 'studs') this.dirty.studs = true
      else if (table == 'ens') this.dirty.ens = true
      else if (table == 'doz') this.dirty.doz = true
      else if (table == 'ur') this.dirty.ur = true
      else if (table == "times") this.dirty.times = true
      this.dirty.stuPlan = true
      this.tmp = !this.tmp
    },

    toggleRes(id, nr) {
      let ur= this.kurs.ur[this.ixUr[id]]
      for (let i=0; i < nr; i++) {
        if (ur.lessons[i] === null) ur.lessons[i] = 0
      }
      ur.lessons[nr] = ur.lessons[nr]==-1 ? 0 : -1
      this.setDirty()
    },
    clearAllLessons () {
      if (!confirm("Stundenplan und Reservierungen zurücksetzen?")) return
      let o
      for (o of this.kurs.doz) {
        o.lessons=[]
      }
      for (o of this.kurs.ur) {
        o.lessons=[]
      }
      this.initStuplan()
      this.setDirty()
    },

    setTimes(id) {
      let nr= this.ixTimes[id], bBreak, s,
      t = this.kurs.times[nr].tt.split(',')
  
      //t = t.filter(z => z != "")
      this.times = [] 
      for (let i of t) {
        if (i) {
          s= '' + i
          bBreak = s.indexOf("|") > -1
          if (bBreak) s = s.replace('|', '')
          this.times.push({bez: s, break: bBreak, uez: s.indexOf("*") > -1})
        }
      }
      
      this.kurs.timesId = id
      
      for (let ur of this.kurs.ur) {
        if (ur.lessons === undefined) ur.lessons=[]
        while (ur.lessons.length < this.times.length) ur.lessons.push(0)
      }
      this.setDirty()
      this.tmp = !this.tmp
      //console.log('setTimes')
    },
    setPrintOptions(printOptions) {
      this.kurs.printOptions.fsStuds= printOptions.fsStuds
      this.kurs.printOptions.fsDoz= printOptions.fsDoz
      this.kurs.printOptionscols= printOptions.cols   
      this.setDirty()
    },
  /* -------------   backups  --------------------------*/
    setHoldBackups(days) {
      this.ini.holdBackups = days
      this.setDirty()
    },
/* ----------------------------------- */

    deactivate() {
      if (!confirm('Stundenplan der User-app deaktivieren?')) return
      const s = "deactivate=1"
      axios({
        method: 'post',
        url: 'api.php',
        data: s,
        responseType: 'json',
      
      }).then (response => {
        if (response.data.err==1) {
          //alert(response.data.msg)
        } else {
          this.ini.published= ""
          //this.tmp = !this.tmp
        }
      }) 

    },
  },
  computed: {
    show: {
      get: function() {
        return this.FShow
      },
      set: function(AShow) {
        if (AShow==1 || AShow==5) {
          if (this.dirty.stuPlan) {
            this.initStuplan()
            this.dirty.stuPlan = false
          }
        }
        this.FShow = AShow
      }
    },
  },

  created() {
    this.today= global.fmtDate(new Date())
    const s = "getKurse=1"
    //alert(process.env.NODE_ENV)
    axios({
      method: 'post',
      url: 'api.php',
      data: s,
      responseType: 'json',
      //timeout: 200   
    }).then (response => {
      //console.log(response)
      if (response.data.err==1) {
        this.kurse= [{id:1, bez:"Kurs 1", von:"2020-11-16"}] 
      } else {
        //console.log(response.data)
        this.dirty.kurse = false 
        this.kurse = response.data.kurse
        this.config = response.data.config
        this.ini = response.data.ini
        if (!this.ini.holdBackups) this.ini.holdBackups = 30
        
        this.reindexKurse()
        
        const nr= this.ixKurse[this.config.lastId]
        this.initKurs(response.data.kurse[nr])

        this.activeRowId = this.config.lastId
        if (!this.ixKurse[this.activeRowId]) this.activeRowId = this.kurse[0].id

        this.dirty.kurse = false
        bus.$emit("setVersion", this.ini.ver)
        
      }
    }) 
  },
  destroyed() {
    
  },

  mounted: function() {

  }
}
</script>

<style scoped lang="sass">
#wait
  
  text-align: center
  background: rgba(0,0,0,0.1)
  
.nav
  border-bottom: 1px #bbb solid
  text-align: center
  margin: 0 0 1em 0
  padding: 1px 0 0px 0

  
  ul
    display: inline
    margin: 0 auto

  li
    cursor: pointer
    display: inline
    padding: 0 1em 2px 1em
    border-right: 1px solid silver
    margin: 0
    line-height: 100%

    span
      padding-right: 0.6em
      font-size: 14pt
      position: relative
      top: 2px
      

  li.active
    font-weight: bold
    
  li:first-child
    border-left: 1px solid silver

table
  td
    text-align: left
    padding: 6px 1px 4px 1px
    border: none

  button
    padding: 1px 2px

div.kurs
  margin: 3px auto
  border: 1px solid gray
  padding: 0
  width: 500px

  td.h-right
    padding: 3px 10px

  div.header
    table
      td
        padding: 2px 10px
        cursor: pointer
      td.icon 
        width: 25px
      td.bez
        min-width: 120px
  
  input.bez, .b-form-datepicker
    width: 155px
  
  input.caption
    width: 382px
  
  .file
    width: 290px
  .btnUpload
    margin-left: 2px
   
  table.detail
    width: 100%
    margin-bottom: 10px 

    button
      padding: 5px 8px
      font-size: 10pt

div.kurs.active
  div.header
    background: #ddd
    border-bottom: 1px solid silver

    td 
      font-weight: bold

.bi-circle-fill
  color: silver
.bi-circle-fill.active
  color: #d23131f7 

</style>