<template>
  <div id="PanelDoz">
    <h3>Dozenten</h3>
    <p class="invis">{{tmp}}</p>
   
    <div class="head-buttons">
      <b-button size="sm" @click="$emit('insert', 'doz')" variant="success">Neuer Dozent</b-button>
      <b-button size="sm" @click="kill(activeId)" variant="danger" :disabled="activeId==-1">Dozent löschen</b-button>
      <b-button size="sm" @click="$emit('save-kurs')" :disabled="!dirty">Speichern</b-button>
    </div>
  
    <table class="data">
      <tr >
        <th></th>
        <th>Name</th>
        <th>Vorname</th>
        <th>gekürzt</th>
        <th>PIN</th>
        <th></th>
      </tr>
      <tr v-for="(d,key) in doz" :key=key :class="{active: activeId==d.id}">
        <td @click="rowClick(d.id)"><b-icon-circle-fill @click="kill(d.id)" /></td>
        <td class="fname" @click="rowClick(d.id)">
          <input v-if="activeId==d.id" type="text" title="Name" v-model.trim="d.name" @input="$emit('set-dirty', 'doz')">
          <span v-else>{{d.name}}</span>
        </td>
        <td class="vname" @click="rowClick(d.id)">
          <input v-if="activeId==d.id" type="text" title="Vorname" v-model.trim="d.vname" @input="$emit('set-dirty', 'doz')">
          <span v-else>{{d.vname}}</span>
          </td>
        <td class="fname" @click="rowClick(d.id)">
          <input v-if="activeId==d.id" type="text" title="kurz" v-model.trim="d.pname" @input="$emit('set-dirty', 'doz')">
          <span v-else>{{d.pname}}</span>
        </td>
        <td class="pin" @click="rowClick(d.id)">
          <input v-if="activeId==d.id" type="text" title="pin" v-model.trim="d.pin" @input="$emit('set-dirty', 'doz')">
          <span v-else>{{d.pin}}</span>
          </td>
        <td @click="rowClick(d.id)" class="info">
          Einzelunterricht: <b>{{d.jobs.einzel}}</b><span v-if="d.jobs.ens">, Ensembles: <b>{{d.jobs.ens}}</b></span>
          <table v-if="activeId==d.id">
            <tr v-for="(di, k) in d.jobs.list" :key=k>
              <td>{{di.name}}</td>
              <td>{{di.id >= 1000 ? 1 : di.soll}}</td>
            </tr>

          </table>
        </td>
        <!--
        <td @click="rowClick(d.id)">{{d.lessons}}</td>
        -->
      </tr>
    </table>
  </div>
</template>

<script>
import { bus } from '../main'
export default {
  name: "PanelDoz",
  data() {
    return {
      activeId: -1,
      dozInfo: [],   
    }
  },
  props: ['doz', 'studs', 'ens', 'ixStuds', 'ixEns', 'dirty', 'tmp', 'insert', 'del', 'set-dirty', 'save-kurs'],
  methods: {
    rowClick(id) {
      this.activeId= id
    },
    kill(id) {
      if (this.activeId == id) this.$emit('del', 'doz', id)
    },
  },
  computed: {
    dozExt() {
      //let t= this
      return this.doz.map((b) => {
        /*
        let ens, stud
        b.job= {hStuds: 0, hEns: 0, list:[]}
        for (let id of b.studs) {
          if (id >= 1000) {
            ens= this.ens[this.ixEns[id-1000]]
            b.job.list.push({name: ens.name, count:1})
            b.job.hEns ++
          } else if (id > 0) {
            stud = this.studs[this.ixStuds[id]]
            for (let doz of stud.doz) {
              if (doz.id == b.id) {
                b.job.hStuds += doz.soll
                b.job.list.push({name: '  '+stud.name + " " + stud.vname, count: doz.soll})
                break
              } 
            }  
          }
        }
        b.job.list.sort(function(x,y) {
          return x.name.localeCompare(y.name)
        })
        b.job.list.map((n) => {
          n.name = n.name.trim()
          return n
        })
        */
        return b
      })
    },
    /*
    tmpCalendar: {
      get: function() {
        if (this.week==1) {
          return this.calendar.slice(7,14)
        } else {
          return this.calendar.slice(0,7)  
        } 
      }
    }
    */
  },
  created() {
    bus.$on('setActiveRow', (AId) => {
      this.activeId = AId
    })

    if (this.doz.length) this.activeId= this.doz[0].id
  },
}
</script>

<style scoped lang="sass">
tr.active
  td
    vertical-align: top

.info
  padding-left: 0.6em
  padding-right: 1em
  cursor: pointer
  td
    color: #2c3e50 !important
    border-top: 1px dotted silver
    border-bottom: none
    padding-left: 0 !important
    padding-right: 5px
</style>