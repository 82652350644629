<template>
  <div id="PanelStud">
    <h3>Teilnehmer</h3>
    <!--
    <p class="invis">{{tmp}}</p>
    -->
    <div class="head-buttons">
      <b-button size="sm" @click="$emit('insert', 'studs')" variant="success">Neuer Teilnehmer</b-button>
      <b-button size="sm" @click="kill(activeId)" variant="danger" :disabled="activeId==-1">Teilnehmer löschen</b-button>
      <b-button size="sm" @click="$emit('save-kurs')" :disabled="!dirty">Speichern</b-button>
    </div>
    
    <table class="studTable data">
      <tr>
        <th></th>
        <th>
          <input class="filter" type="text" placeholder="Suchen..." v-model.trim="filterName" /><br>
          Name
        </th>
        <th>Vorname</th>
        <th>gekürzt</th>
        <th>PIN</th>
        <th>Kurse | <span class="ensList">Ensembles</span></th>
      </tr>
      <tr v-for="(stud,key) in filteredStuds" :key=key :class="{active: activeId==stud.id}">
        <td @click="rowClick(stud.id)"><b-icon-circle-fill @click="kill(stud.id)" /></td>
        <td class="fname" @click="rowClick(stud.id)">
          <input v-if="activeId==stud.id" title="Name" v-model.trim="stud.name" @input="$emit('set-dirty', 'studs')">
          <span v-else>{{stud.name}}</span>        
        </td>
        <td class="vname" @click="rowClick(stud.id)">
          <input v-if="activeId==stud.id" title="Vorname" v-model.trim="stud.vname" @input="$emit('set-dirty', 'studs')">
          <span v-else>{{stud.vname}}</span>  
        </td>
        <td class="fname" @click="rowClick(stud.id)">
          <input v-if="activeId==stud.id" title="kurz" v-model.trim="stud.pname" @input="$emit('set-dirty', 'studs')">
          <span v-else>{{stud.pname}}</span>  
        </td>
        <td class="pin" @click="rowClick(stud.id)">
          <input v-if="activeId==stud.id" title="pin" v-model.trim="stud.pin" @input="$emit('set-dirty', 'studs')">
          <span v-else>{{stud.pin}}</span>  
        </td>

        <td @click="rowClick(stud.id)" class="plus" >
          
          <b-button title="Neue Kurseinheit(en)" size="sm" @click="$emit('add-doz', stud.id)" variant="success"><b-icon-plus /></b-button>
          &nbsp;
          <!-- {{stud.doz}} -->
          <div v-if="activeId==stud.id" class="kursContainer">
            <div class="kurs" v-for="(k,key2) in stud.doz" :key=key2>
              <dropdown :options="ddOptions" :label="k.name" @show="beforeDDOpen(key2,stud.doz, key, key2)" @dropdownitem-click="dropdownitemClick" :markerColor = "markerColor" />
              <input class="soll" v-model.number="k.soll" @keydown="$emit('set-dirty', 'studs')">
            </div>
          </div>
          <div v-else class="kursContainer">
            <p v-for="(k,key2) in stud.doz" :key=key2>
              {{k.name}} ({{k.soll}})
            </p>
    
          </div>
          <span class="ensList">{{stud.ensList}}</span>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { bus } from '../main'
import dropdown from "./Dropdown.vue"

export default {
  name: "PanelStud",
  data() {
    return {
      activeId: -1,
      activeNr: -1,
      markerColor: 'navy',
      ddOptions: [{disabled: false, name: ""}, ],
      FFilterName: '',
      filterName: '',
    }
  },
  props: ['studs', 'ens', 'doz', 'ixDoz', 'dirty', 'tmp', 
     'insert', 'del', 'change-stud-doz', 'set-dirty', 'save-kurs', 'add-doz'],
  components: {dropdown},
  methods: {
    beforeDDOpen(kursNr, kurse, key, key2) {
      let list= this.filterDoz(kursNr, kurse), ret=[], l={}
      for (l of list) {
        ret.push({id: l.id, caption: this.shortName(l), key: key, key2: key2, disabled: false})
      }
      ret.push({caption:"<i class='fa fa-lg fa-times text-danger'></i> löschen", id: 0, key: key, key2: key2})
      this.ddOptions = ret
    },

    dropdownitemClick(el) {
      this.$emit('change-stud-doz', el.key, el.key2, el.id)
    },

    kill(id) {
      if (this.activeId == id) this.$emit('del', 'studs', id)
    },
    shortName: (p) => {
      return p.name + (p.vname ? ' ' + p.vname[0] + '.' : '')
    },

    getDozName(id) {
      return id==0 ? ' ... ' : this.shortName(this.doz[this.ixDoz[id]])
    },

    rowClick(id) {
      this.activeId= id
    },
    filterDoz(kursNr,kurse) {
      if (kursNr==0) return this.doz
      else {
        let ret=[]
        for (let i=0; i<kursNr; i++) {
          if (kurse[i].id) ret.push(kurse[i].id)
        }
        return this.doz.filter(
          z => ret.indexOf(z.id) == -1
        )
      }
    },

  },
  computed: {
    studsExt() {
      return this.studs.map((stud) => {
        let r= []
        for (let ens of this.ens) {
          for (let s of ens.studs) {
            if (stud.id == s) {
              r.push(ens.name)
              break
            }
          }
        }
        if (r.length) {
          r.sort(function(a,b) {
            return a.localeCompare(b)
          })
          stud.ensList = "["+r.join(", ")+"]"
        } 
        for (let doz of stud.doz) {
          
          if (doz.id > 0) {
            let d= this.doz[this.ixDoz[doz.id]]
            try {
              doz.name = d.name + (d.vname ? ' ' + d.vname[0] + '.' : '')   
            }
            catch (e) {
              // Anweisungen für jeden Fehler
              console.log(e); // Fehler-Objekt an die Error-Funktion geben
              console.log(stud)
            }
            
            
          } else doz.name= "..."
        }
        return stud
      })
    },
    filteredStuds() {
      return this.studsExt.filter(z => z.name.toUpperCase().startsWith(this.filterName.toUpperCase()))
    }
    
  },
  beforeUpdate() { 
    //alert(this.filteredStuds.length)  
  },
  created() {
    bus.$on('setActiveRow', (AId) => {
      this.activeId = AId
    })
    //this.filteredStuds = this.studsExt
    if (this.studs.length) this.activeId= this.studsExt[0].id
  }
}
</script>

<style scoped lang="sass">
  table.data
    margin: 0.5em auto 1em auto
    th
      vertical-align: bottom
      padding: 0 5px

    input.filter
      width: 75px
      border: 1px solid silver

  div.kurs, div.kursContainer 
    display: inline-block
    margin: 0
    
  div.kurs
    padding: 0 8px 0 0
    .dropdown
      margin: 2px 2px 2px 0
    
  div.kursContainer 
    padding: 0 4px 0 6px
    cursor: pointer
    p 
      display: inline
      margin: 0
      padding: 0 6px 0 4px
  
  td.plus
    padding: 0 8px

  .ensList
    font-style: italic
    cursor: pointer 
    
  .extraButton
    padding-left: 10px
</style>